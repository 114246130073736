export const mainLightColors = {
  main: '#0061ed',
  main100: '#0556cc',

  white: '#fff',
  white_1: '#fff',

  black: '#000',
  black_alpha20: 'rgba(0, 0, 0, 0.2)',
  black_alpha20_1: 'rgba(0, 0, 0, 0.2)',
  black100: '#151b26',
  black100_alpha15: 'rgba(21, 27, 38, 0.15)',
  black100_alpha30: 'rgba(21, 27, 38, 0.3)',
  black100_alpha50: 'rgba(21, 27, 38, 0.5)',
  black200: '#141b27',
  black200_1: '#141b27',
  black200_alpha24: 'rgba(20, 27, 39, 0.24)',
  black200_alpha40: 'rgba(20, 27, 39, 0.4)',
  black200_alpha40_1: 'rgba(20, 27, 39, 0.4)',

  gray: '#f3f4f5',
  gray_1: '#f3f4f5',
  gray100: '#eaecef',
  gray200: '#f4f6f7',
  gray300: '#f6f7f8',
  gray400: '#ecedee',
  gray400_1: '#ecedee',
  gray400_2: '#ecedee',
  gray500: '#a5adba',
  gray600: '#f6f7fa',
  gray700: '#7c7f85',
  gray800: '#f8f8f8',
  gray900: '#919caf',
  gray1000: '#ebecf0',
  gray1000_1: '#ebecf0',
  gray1000_alpha30: 'rgba(235, 236, 240, 0.3)',
  gray1100: '#ededed',
  gray1200: '#f1f1f1',
  gray1300: '#d0d5dc',
  gray1300_alpha50: 'rgba(208, 213, 220, 0.5)',
  gray1300_alpha50_1: 'rgba(208, 213, 220, 0.5)',
  gray1400: '#dfdfe1',
  gray1500: '#a5adba',
  gray1600_alpha50: 'rgba(176, 186, 201, 0.5)',
  gray1700: '#e8e8e8',
  gray1800: '#e6e6e6',
  gray1900: '#bababa',
  gray2000: '#6c7075',
  gray2100: '#d3dadf',
  gray2200: '#6c7075',

  green: '#429834',

  blue: '#0061ed',
  blue_1: '#0061ed',
  blue100: '#505f79',
  blue200: '#172b4d',

  red: '#fff4f4',
  red_1: '#fff4f4',
  red100: '#f84949',

  whiteGradient: `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 40%,
      rgb(255, 255, 255) 100%
    )`,
  whiteGradient_1: `linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 40%,
      rgb(255, 255, 255) 100%
    )`,
  whiteGradient_2: `linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0.1) 40%,
      rgb(255, 255, 255) 100%
    )`,
  whiteGradient100: `linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, .88) 28%,
      #fff 66%)`,

  whiteShadow: '0 -4px 8px 0 #fff',

  blackShadow: '0 4px 4px 0 rgba(23, 43, 77, 0.25)',
  blackShadow100: '0 5px 30px 0 rgba(23, 43, 77, 0.1)',
  blackShadow200: '0 5px 10px 0 rgba(23, 43, 77, 0.1)',
  blackShadow300: '0 20px 20px 0 rgba(23, 43, 77, 0.1)',
  blackShadow400: '0 -10px 20px 0 rgba(23, 43, 77, 0.1)',

  creditSlider: {
    text: '#000',
  },

  serviceCard: {
    text: '#fff',
  },
}

export const mainDarkColors = {
  ...mainLightColors,

  main: '#fff',

  white: '#141b27',
  white_1: '#1b2433',

  black: '#fff',
  black_alpha20: '#fff',
  black_alpha20_1: 'rgba(255, 255, 255, 0.2)',
  black100: '#fff',
  black100_alpha15: '#fff',
  black100_alpha30: '#fff',
  black100_alpha50: '#fff',
  black200: '#fff',
  black200_1: '#1b2433',
  black200_alpha24: '#fff',
  black200_alpha40: '#fff',
  black200_alpha40_1: '#141b27',

  gray: '#141b27',
  gray_1: '#1b2433',
  gray100: '#1b2433',
  gray200: '#1b2433',
  gray300: '#141b27',
  gray400: '#1b2433',
  gray400_1: 'transparent',
  gray400_2: '#fff',
  gray500: '#1b2433',
  gray600: '#1b2433',
  gray700: '#fff',
  gray800: '#1b2433',
  gray900: '#fff',
  gray1000: '#141b27',
  gray1000_1: '#1b2433',
  gray1000_alpha30: '#1b2433',
  gray1100: '#1b2433',
  gray1200: '#243145',
  gray1300: '#1b2433',
  gray1300_alpha50: '#1b2433',
  gray1300_alpha50_1: 'transparent',
  gray1400: '#1b2433',
  gray1500: '#fff',
  gray1600_alpha50: 'transparent',
  gray1700: '#1b2433',
  gray1800: '#141b27',
  gray1900: '#fff',
  gray2000: '#fff',
  gray2100: '#1b2433',
  gray2200: '#fff',

  blue: '#1b2433',
  blue_1: '#141b27',
  blue100: '#fff',
  blue200: '#fff',

  red: 'transparent',
  red_1: '#1b2433',

  whiteGradient: `linear-gradient(
      90deg,
      rgba(27, 36, 51, 0.1) 40%,
      rgb(27, 36, 51) 100%
    )`,
  whiteGradient_1: `linear-gradient(
      90deg,
      rgba(20, 27, 39, 0.1) 40%,
      rgb(20, 27, 39) 100%
    )`,
  whiteGradient_2: `linear-gradient(
      -90deg,
      rgba(20, 27, 39, 0.1) 40%,
      rgb(20, 27, 39) 100%
    )`,
  whiteGradient100: `linear-gradient(
      180deg,
      rgba(27, 36, 51, 0) 0,
      rgba(27, 36, 51, .88) 28%,
      #1b2433 66%)`,

  whiteShadow: '0 -4px 8px 0 #1b2433',

  blackShadow: '0 4px 4px 0 rgba(255, 255, 255, 0.25)',
  blackShadow100: '0 5px 30px 0 rgba(0, 0, 0, 0.3)',
  blackShadow200: '0 5px 10px 0 rgba(0, 0, 0, 0.3)',
  blackShadow300: '0 20px 20px 0 rgba(0, 0, 0, 0.3)',
  blackShadow400: '0 -10px 20px 0 rgba(0, 0, 0, 0.3)',
}

export const b2bLightColors = {
  ...mainLightColors,

  main: '#0066b3',

  creditSlider: {
    text: '#fff',
  },

  serviceCard: {
    text: '#fff',
  },
}
