import React from 'react'
import styled from '@emotion/styled'

import Filters from '../TableContent/components/Filters'

import { CabinetSelectProps } from '@/components/PersonalCabinetForms/components/CabinetSelect/CabinetSelect'
import {
  IDataTableCheckbox,
  IDataTableSearch,
  IDataTableSort,
} from '@/modules/Cabinet/components/CabinetDataTable/types'

interface Props {
  title?: string
  search?: IDataTableSearch
  sort?: IDataTableSort
  checkbox?: IDataTableCheckbox
  select?: CabinetSelectProps
}

export const TableHeader = ({
  title,
  checkbox,
  search,
  sort,
  select,
}: Props) => {
  return (
    <Component>
      {sort?.options || checkbox || search?.placeholder || select ? (
        <Filters
          sort={sort}
          checkbox={checkbox}
          search={search}
          select={select}
        />
      ) : (
        <Title>{title}</Title>
      )}
    </Component>
  )
}

const Component = styled.div`
  padding: 30px;
  border-bottom: solid 1px ${props => props.theme.main};
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`
