import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Cabinet from '@/modules/Cabinet'
import { CabinetDataTable } from '@/modules/Cabinet/components/CabinetDataTable'
import { OptionType } from '@/typings/common'
import {
  getVisibleTabs,
  handlerCarsSearch,
  handlerInsuranceSearch,
  handlerOtherSearch,
  handlerServiceCheckbox,
  handlerServiceSearch,
} from '@/modules/Purchases/helpers'
import { OPTIONS_LIST, TABS_LIST } from '@/modules/Purchases/constants'
import { useProfile } from '@/providers/Profile'
import {
  getUserPurchasesCars,
  getUserPurchasesInsurance,
  getUserPurchasesOthers,
  getUserPurchasesServices,
} from '@/services/users/users-service'
import PurchasesServiceCard from '@/modules/Cabinet/components/Cards/PurchasesServiceCard'
import { handleSortCabinetDataTableByDate } from '@/modules/PersonalData/utils'
import PurchasesInsuranceCard from '@/modules/Cabinet/components/Cards/PurchasesInsuranceCard'
import PurchasesCarCard from '@/modules/Cabinet/components/Cards/PurchasesCarCard'
import PurchasesOtherCard from '@/modules/Cabinet/components/Cards/PurchasesOtherCard'
import CabinetDataTabs from '@/modules/Cabinet/components/CabinetDataTabs'
import { media } from '@/utils/mixin'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

function Purchases() {
  useDocumentTitle('Кабинет Атлант-М - Мои покупки')
  const { preview } = useProfile()
  const [activeTabName, setActiveTabName] = React.useState<string>('')

  const tabs: OptionType[] = getVisibleTabs(
    TABS_LIST,
    preview.data?.availability ?? null,
  )

  React.useLayoutEffect(
    () => {
      setActiveTabName(tabs ? tabs[0].value : '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  return (
    <Cabinet activeTab={'PURCHASES'}>
      <Wrapper>
        <CabinetDataTabs
          tabs={tabs}
          activeTabName={activeTabName}
          setActiveTabName={(value: string) => setActiveTabName(value)}
        />
        <TabsContent isVisible={activeTabName === 'service'}>
          <CabinetDataTable
            dataFetch={() => getUserPurchasesServices()}
            rowComponent={PurchasesServiceCard}
            search={{
              placeholder: 'Номер заказа-наряда, сумма, дата',
              handler: handlerServiceSearch,
            }}
            sort={{
              options: OPTIONS_LIST,
              handler: handleSortCabinetDataTableByDate,
            }}
            checkbox={{
              label: 'показывать только ТО',
              name: 'to',
              defaultValue: false,
              handler: handlerServiceCheckbox,
            }}
          />
        </TabsContent>
        <TabsContent isVisible={activeTabName === 'insurance'}>
          <CabinetDataTable
            dataFetch={() => getUserPurchasesInsurance()}
            rowComponent={PurchasesInsuranceCard}
            search={{
              placeholder: 'Номер, сумма, дата',
              handler: handlerInsuranceSearch,
            }}
            sort={{
              options: OPTIONS_LIST,
              handler: handleSortCabinetDataTableByDate,
            }}
          />
        </TabsContent>
        <TabsContent isVisible={activeTabName === 'cars'}>
          <CabinetDataTable
            dataFetch={() => getUserPurchasesCars()}
            rowComponent={PurchasesCarCard}
            search={{
              placeholder: 'Номер, сумма, дата',
              handler: handlerCarsSearch,
            }}
            sort={{
              options: OPTIONS_LIST,
              handler: handleSortCabinetDataTableByDate,
            }}
          />
        </TabsContent>
        <TabsContent isVisible={activeTabName === 'other'}>
          <CabinetDataTable
            dataFetch={() => getUserPurchasesOthers()}
            rowComponent={PurchasesOtherCard}
            search={{
              placeholder: 'Номер заказа, сумма, дата',
              handler: handlerOtherSearch,
            }}
            sort={{
              options: OPTIONS_LIST,
              handler: handleSortCabinetDataTableByDate,
            }}
          />
        </TabsContent>
      </Wrapper>
    </Cabinet>
  )
}

export default Purchases

const Wrapper = styled.div`
  padding: 30px 0;

  ${media.tabletSmall(css`
    padding: 0;
  `)}

  ${media.mobile(css`
    overflow: hidden;
  `)}
`

const TabsContent = styled.div<{ isVisible: boolean }>`
  display: none;
  background: ${props => props.theme.white};

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `}
`
