import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'

import {
  initialServiceChooseCarFormValues,
  validationServiceChooseCarAutoFormSchema,
  validationServiceChooseCarByHandFormSchema,
} from './ServiceChooseCarForm.helpers'
import { ServiceChooseCarFormFields, ServiceChooseCarFormProps } from './types'
import {
  getBrands,
  getCarBrandByCarId,
  getCarId,
  getCarIdByBrand,
  getCarOptions,
  getInitialActiveBrandOption,
  getInitialActiveYearOption,
  getInitialCarOption,
  getYears,
} from './utils'

import { useProfile } from '@/providers/Profile'
import { OptionType } from '@/typings/common'
import { DefaultOption } from '@/modules/PersonalData/utils'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import { handleValidateField, TypesOfValidate } from '@/utils/textInput'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'

function ServiceChooseCarForm({
  onNextStep,
  onPreviousStep,
}: ServiceChooseCarFormProps) {
  const { brands, service } = useProfile()
  const cars = service.cars
  const chooseCarData = service.chooseCarData

  const carsOptions = getCarOptions(cars)
  const brandsOptions = getBrands(brands.data)
  const yearsOptions = getYears()

  const [activeCarOption, setActiveCarOption] = useState<OptionType>(
    getInitialCarOption(chooseCarData, cars),
  )
  const [activeBrandOption, setActiveBrandOption] = useState<OptionType>(
    getInitialActiveBrandOption(chooseCarData, brands.data),
  )
  const [activeYearOption, setActiveYearOption] = useState<OptionType>(
    getInitialActiveYearOption(chooseCarData),
  )

  const isHelpOption =
    activeCarOption.value === 'Выберите ваше авто' ||
    activeCarOption.value === 'Ввести вручную'
  const isManualInput = activeCarOption.value === 'Ввести вручную'

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik<ServiceChooseCarFormFields>({
    initialValues: initialServiceChooseCarFormValues,
    validationSchema: !isManualInput
      ? validationServiceChooseCarAutoFormSchema
      : validationServiceChooseCarByHandFormSchema,
    onSubmit: values => {
      onNextStep(values)
    },
  })

  useEffect(() => {
    if (chooseCarData) {
      setValues(chooseCarData)
    }
  }, [chooseCarData, setValues])

  const handleChangeCarSelect = (option: OptionType) => {
    if (activeCarOption === option) {
      return
    }

    setActiveCarOption(option)

    if (getCarId(cars, option.value)) {
      resetForm()
      setFieldValue('carId', getCarId(cars, option.value))
      setFieldValue('carBrand', getCarBrandByCarId(cars, option.value))
    } else {
      resetForm()
      setActiveBrandOption(DefaultOption)
      setActiveYearOption(DefaultOption)
    }
  }

  const handleChangeBrandSelect = (option: OptionType) => {
    setActiveBrandOption(option)
    setFieldValue('carBrand', getCarIdByBrand(brands.data, option.value))
  }

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      <CabinetFormTemplate title={'Данные автомобиля'}>
        <FormRow>
          <FormCol>
            <CabinetFormField label={'Ваше авто'}>
              <CabinetSelect
                value={activeCarOption}
                options={carsOptions}
                onChange={handleChangeCarSelect}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        {isManualInput && (
          <React.Fragment>
            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Бренд'}
                  error={
                    errors.carBrand && touched.carBrand
                      ? errors.carBrand
                      : undefined
                  }
                >
                  <CabinetSelect
                    value={activeBrandOption}
                    options={brandsOptions}
                    onChange={handleChangeBrandSelect}
                  />
                </CabinetFormField>
              </FormCol>

              <FormCol>
                {values.carBrand !== 0 && (
                  <CabinetFormField
                    label={'Модель'}
                    error={
                      errors.carModel && touched.carModel
                        ? errors.carModel
                        : undefined
                    }
                  >
                    <CabinetTextInput
                      id='carModel'
                      type='text'
                      name='carModel'
                      value={values.carModel}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                )}
              </FormCol>
            </FormRow>

            {values.carBrand !== 0 && (
              <React.Fragment>
                <FormRow>
                  <FormCol customWidth={50}>
                    <CabinetFormField
                      label={'Год'}
                      error={
                        errors.carYear && touched.carYear
                          ? errors.carYear
                          : undefined
                      }
                    >
                      <CabinetSelect
                        value={activeYearOption}
                        options={yearsOptions}
                        onChange={option => {
                          setActiveYearOption(option)
                          setFieldValue('carYear', Number(option.value))
                        }}
                      />
                    </CabinetFormField>
                  </FormCol>
                </FormRow>

                <FormRow>
                  <FormCol>
                    <CabinetFormField
                      label={'VIN (необязательно)'}
                      error={
                        errors.carVin && touched.carVin
                          ? errors.carVin
                          : undefined
                      }
                    >
                      <CabinetTextInput
                        id='carVin'
                        type='text'
                        name='carVin'
                        value={values.carVin}
                        onChange={handleChange}
                      />
                    </CabinetFormField>
                  </FormCol>

                  <FormCol>
                    <CabinetFormField
                      label={'Гос. номер'}
                      error={
                        errors.carPlate && touched.carPlate
                          ? errors.carPlate
                          : undefined
                      }
                    >
                      <CabinetTextInput
                        id='carPlate'
                        type='text'
                        name='carPlate'
                        value={values.carPlate}
                        onChange={handleChange}
                      />
                    </CabinetFormField>
                  </FormCol>
                </FormRow>

                <FormRow>
                  <FormCol>
                    <CabinetFormField
                      label={'Пробег'}
                      error={
                        errors.carMileage && touched.carMileage
                          ? errors.carMileage
                          : undefined
                      }
                    >
                      <CabinetTextInput
                        id='carMileage'
                        name='carMileage'
                        value={values.carMileage}
                        onChange={event =>
                          handleValidateField(
                            event,
                            handleChange,
                            TypesOfValidate.NUMBER,
                            7,
                          )
                        }
                      />
                    </CabinetFormField>
                  </FormCol>
                </FormRow>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <FormAction mobileActionReverse>
          <FormActionCol customWidth={50}>
            <CabinetButton variant={'contained'} onClick={onPreviousStep}>
              Назад
            </CabinetButton>
          </FormActionCol>
          {(!isHelpOption || (isManualInput && values.carBrand !== 0)) && (
            <FormActionCol customWidth={50}>
              <CabinetButton type='submit' variant={'contained'}>
                Далее
              </CabinetButton>
            </FormActionCol>
          )}
        </FormAction>
      </CabinetFormTemplate>
    </Component>
  )
}

export default ServiceChooseCarForm

const Component = styled.form``
