import { CardDetailsContent } from './constants'

import {
  CardDetailsItemType,
  IUserRequestsType,
} from '@/services/users/typings'
import {
  getHoursAndMinutes,
  getHoursAndMinutesFormattedText,
} from '@/modules/PersonalData/utils'

export function getTableHeaderList(content: string) {
  switch (content) {
    case CardDetailsContent.PriceWithVAL:
      return ['Кол-во', 'Стоимость', 'Скидка', 'НДС', 'Итого с НДС']
    case CardDetailsContent.PriceTO:
      return ['Кол-во', 'Обязательные', 'Стоимость']
    case CardDetailsContent.Spare:
      return [
        'Код',
        'Кол-во',
        'Стоимость USD',
        'Стоимость BYN',
        'Дата поступления',
        'Статус',
      ]
    default:
      return ['Кол-во', 'Стоимость']
  }
}

export function getWorksAndPartsRow(details: CardDetailsItemType[]) {
  return details.map(detail => {
    return {
      name: detail?.name ?? '',
      row: [
        `${detail.count} ${detail.units?.toLowerCase()}`,
        `${detail.summ.value}`,
        `${detail.summ.sale}`,
        `${detail.summ.tax}`,
        `<span>${detail?.summ.total}</span>
          ${detail?.summ.currency}`,
      ],
    }
  })
}

export function getWorksRowForTO(details: CardDetailsItemType[]) {
  return details.map(detail => {
    const detailHoursAndMinutes = getHoursAndMinutesFormattedText(
      getHoursAndMinutes(detail.count).hours,
      getHoursAndMinutes(detail.count).minutes,
    )

    return {
      name: detail?.name ?? '',
      row: [
        `${detailHoursAndMinutes}`,
        `${detail.required ? 'Да' : 'Нет'}`,
        `<span>${detail?.summ.total}</span>
          ${detail?.summ.currency}`,
      ],
    }
  })
}

export function getPartsRowForTO(details: CardDetailsItemType[]) {
  return details.map(detail => {
    return {
      name: detail?.name ?? '',
      row: [
        `${detail.count} ${detail.units}`,
        `${detail.required ? 'Да' : 'Нет'}`,
        `<span>${detail?.summ.total}</span>
          ${detail?.summ.currency}`,
      ],
    }
  })
}

export function getTotalRowForRequiredTO(details: CardDetailsItemType) {
  const detailHoursAndMinutes = getHoursAndMinutesFormattedText(
    getHoursAndMinutes(Number(details.time?.required)).hours,
    getHoursAndMinutes(Number(details.time?.required)).minutes,
  )

  return [
    {
      name: details?.name ?? '',
      row: [
        `${detailHoursAndMinutes.trim() ? detailHoursAndMinutes : '-'}`,
        `<span>${details?.summ.required}</span>
          ${details?.summ.currency}`,
      ],
    },
  ]
}

export function getTotalRowForRecommendedTO(details: CardDetailsItemType) {
  const detailHoursAndMinutes = getHoursAndMinutesFormattedText(
    getHoursAndMinutes(Number(details.time?.recommended)).hours,
    getHoursAndMinutes(Number(details.time?.recommended)).minutes,
  )

  return [
    {
      name: details?.name ?? '',
      row: [
        `${detailHoursAndMinutes.trim() ? detailHoursAndMinutes : '-'}`,
        `<span>${details?.summ.recommended}</span>
          ${details?.summ.currency}`,
      ],
    },
  ]
}

export function getTotalRowForTO(details: CardDetailsItemType) {
  const detailHoursAndMinutes = getHoursAndMinutesFormattedText(
    getHoursAndMinutes(Number(details.time?.total)).hours,
    getHoursAndMinutes(Number(details.time?.total)).minutes,
  )

  return [
    {
      name: details?.name ?? '',
      row: [
        `${detailHoursAndMinutes.trim() ? detailHoursAndMinutes : '-'}`,
        `<span>${details?.summ.total}</span>
          ${details?.summ.currency}`,
      ],
    },
  ]
}

export function getSpareRow(details: IUserRequestsType[]) {
  return details.map(detail => {
    return {
      name: detail.name,
      row: [
        `${detail.number}`,
        `${detail.quantity} ${detail.unit?.toLowerCase()}`,
        `${detail.originalPrice}`,
        `${detail.price}`,
        `${detail.arrivalDate ?? '-'}`,
        `${detail.status}`,
      ],
    }
  })
}
