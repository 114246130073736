import { Nullable } from '@/typings/common'
import {
  ServiceTOPartsAndWorks,
  ServiceTOPayloadDetails,
} from '@/services/users/typings'

export const getServiceTOPartsAndWorks = (
  data: Nullable<ServiceTOPayloadDetails>,
  fieldType: 'parts' | 'works',
  workType: 'required' | 'recommended',
): ServiceTOPartsAndWorks[] => {
  if (!data || !data[fieldType] || data[fieldType].length < 1) {
    return []
  }

  const filteredData = data[fieldType].filter(({ required }) =>
    workType === 'required' ? required : !required,
  )

  return filteredData ? filteredData : []
}
