import React from 'react';

import { PurchasesInsuranceItemType } from '@/services/users/typings';
import CardTemplate from '@/modules/Cabinet/components/Cards/components/CardTemplate';
import CardTop from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardTop';
import CardDate from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardDate';
import CardBill from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardBill';
import CardPreview from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardPreview';

interface Props {
  card: PurchasesInsuranceItemType;
}

function PurchasesInsuranceCard({ card }: Props) {
  const { date, dealer, type, price, number, currency, manager } = card;
  return (
    <CardTemplate isPointer={false}>
      <CardTop>
        <CardDate date={date} />
        <CardPreview boldText={'№' + number} dealer={dealer}>
          {type && <div>Тип страховки · {type}</div>}
          {manager && <div>Менеджер · {manager}</div>}
        </CardPreview>
        <CardBill total={price} currency={currency} />
      </CardTop>
    </CardTemplate>
  );
}

export default PurchasesInsuranceCard;
