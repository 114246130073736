import React from 'react'

import { Nullable } from '@/typings/common'

export const defaultScrollTop: number = 80

export const defaultScrollBehavior: ScrollBehavior = 'smooth'

export function scrollToDomElement(
  element: HTMLElement,
  top = defaultScrollTop,
  behavior = defaultScrollBehavior,
) {
  window.scrollTo({
    top: element.getBoundingClientRect().top + window.scrollY - top,
    behavior,
  })
}

export function handleScrollToDomElement(
  element: string | React.MutableRefObject<Nullable<HTMLElement>>,
  top = defaultScrollTop,
  behavior = defaultScrollBehavior,
) {
  const foundElement =
    typeof element === 'string'
      ? document.getElementById(element)
      : element.current

  if (!foundElement) {
    return
  }

  scrollToDomElement(foundElement, top, behavior)
}

export function scrollToTop(behavior = defaultScrollBehavior) {
  window.scrollTo({
    top: 0,
    behavior,
  })
}
