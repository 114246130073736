import React from 'react'

import * as S from './styles'
import { ButtonProps, AnchorProps } from './types'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <S.StyledButton ref={ref} {...props} />
  },
)

export const ButtonLink = React.forwardRef<HTMLAnchorElement, AnchorProps>(
  (props, ref) => {
    return <S.StyledLink ref={ref} {...props} />
  },
)

export default Button
