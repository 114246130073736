import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import moment from 'moment'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  dealersWithoutBooking,
  isOnlineServiceDisabled,
  workTypes,
} from './constants'
import { ServiceWorkTypes } from './components/ServiceDetails/types'
import {
  initialServiceChooseCarCenterFormValues,
  validationServiceChooseCarCenterFormSchema,
} from './ServiceChooseCarCenterForm.helpers'
import ServiceDetails from './components/ServiceDetails'
import {
  ServiceChooseCarCenterFormFields,
  ServiceChooseCarCenterFormProps,
} from './types'
import {
  getDealerOptionByDealerId,
  getServiceTOIdByName,
  getServiceTOOptionById,
  getServiceTOOptions,
  handleSetCommentIfNotBooking,
  hasCustomFormErrors,
} from './utils'

import { ReactComponent as TimeIcon } from '@/assets/svg/time.svg'
import { useProfile } from '@/providers/Profile'
import {
  DefaultOption,
  getDealerId,
  getDealerOptions,
  getFullDealerOptions,
} from '@/modules/PersonalData/utils'
import { Nullable, OptionType } from '@/typings/common'
import {
  ServiceTOPayload,
  ServiceTOPayloadDetails,
} from '@/services/users/typings'
import { media } from '@/utils/mixin'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormNote,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetTabs from '@/components/PersonalCabinetForms/components/CabinetTabs'
import CabinetDataInput from '@/components/PersonalCabinetForms/components/CabinetDataInput/CabinetDataInput'
import { getDayAfterTomorrowDate } from '@/utils/date'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import CabinetTextArea from '@/components/PersonalCabinetForms/components/CabinetTextArea'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import Spinner from '@/components/Spinner'
import TimePicker from '@/components/Picker/TimePicker'
import useUpdateEffect from '@/hooks/useUpdateEffect'
import {
  getServiceAvailableTimeSlots,
  getServiceTO,
  getServiceTODetails,
} from '@/services/users/users-service'

function ServiceChooseCarCenterForm({
  onNextStep,
  onPreviousStep,
  carData,
}: ServiceChooseCarCenterFormProps) {
  const { brands, service, setServiceChooseCarCenterDataAction } = useProfile()
  const carCenterData = service.chooseCarCenterData
  const dealerOptions = carData?.carBrand
    ? getDealerOptions(carData?.carBrand, brands.data)
    : getFullDealerOptions(brands.data)

  const [activeDealerOption, setActiveDealerOption] = useState<OptionType>(
    carCenterData?.dealer
      ? getDealerOptionByDealerId(brands.data, carCenterData.dealer)
      : dealerOptions[0],
  )
  const [serviceTOData, setServiceTOData] = useState<ServiceTOPayload[]>([])
  const [activeServiceTOOption, setActiveServiceTOOption] =
    useState<Nullable<OptionType>>(null)
  const [serviceTODetailsData, setServiceTODetailsData] =
    useState<Nullable<ServiceTOPayloadDetails>>(null)
  const [recordingDate, setRecordingDate] = useState<Nullable<Date>>(null)

  const [isShowTimePicker, setIsShowTimePicker] = useState(false)
  const [serviceTime, setServiceTime] = useState<Nullable<string[]>>(null)

  const [isLoading, setLoading] = useState(false)

  const [activeWorkType, setActiveWorkType] = useState<boolean>(true)

  const isRequiredAndRecommendedTO =
    serviceTODetailsData &&
    serviceTODetailsData.summary.summ.required &&
    serviceTODetailsData.summary.summ.recommended

  const countGetTimeForTo = useRef(0)

  const {
    values,
    errors,
    setFieldValue,
    setErrors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik<ServiceChooseCarCenterFormFields>({
    initialValues: initialServiceChooseCarCenterFormValues,
    validationSchema: validationServiceChooseCarCenterFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (hasCustomFormErrors(values, carData, serviceTime, setErrors)) {
        return
      }

      setSubmitting(true)
      setLoading(true)

      try {
        await onNextStep(values)
      } catch (error: any) {
        console.log(error)
        toast.error(error?.message)
        setLoading(false)
      }

      setSubmitting(false)
      setLoading(false)
    },
  })

  const isDisabledSubmitButton = !!(
    isSubmitting ||
    (carData?.carId &&
      serviceTime &&
      serviceTime?.length < 1 &&
      countGetTimeForTo.current < 3)
  )

  useEffect(() => {
    setFieldValue('dealer', getDealerId(brands.data, activeDealerOption.value))

    if (carCenterData && carCenterData.comment) {
      setFieldValue('comment', carCenterData.comment)
    }
  }, [activeDealerOption.value, brands.data, carCenterData, setFieldValue])

  useUpdateEffect(() => {
    if (
      isOnlineServiceDisabled ||
      !recordingDate ||
      !carData?.carId ||
      dealersWithoutBooking.includes(
        getDealerId(brands.data, activeDealerOption.value),
      ) ||
      !carData?.carBrand
    ) {
      return
    }

    setFieldValue('time', '')
    ;(async () => {
      try {
        setLoading(true)

        const response = await getServiceAvailableTimeSlots(
          getDealerId(brands.data, activeDealerOption.value),
          moment(recordingDate).format('YYYY-MM-DD'),
          carData.carId ?? null,
          values.to ?? null,
          values.recommended ?? false,
        )

        if (response.timeslots.length < 1 && countGetTimeForTo.current !== 3) {
          countGetTimeForTo.current++

          if (countGetTimeForTo.current !== 3) {
            toast.error(
              'По заданным критериям выбора не найдено свободное время для записи online.',
            )
          }
        }

        if (response.timeslots.length > 0) {
          countGetTimeForTo.current = 0
        }

        handleSetCommentIfNotBooking(
          setFieldValue,
          activeServiceTOOption,
          serviceTODetailsData,
          carCenterData,
          carData,
          response.timeslots,
          !values.recommended,
          countGetTimeForTo.current,
        )

        setServiceTime(response.timeslots)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    })()
  }, [brands, recordingDate, values.recommended])

  useUpdateEffect(() => {
    if (
      isOnlineServiceDisabled ||
      !carData?.carId ||
      dealersWithoutBooking.includes(
        getDealerId(brands.data, activeDealerOption.value),
      ) ||
      !carData?.carBrand
    ) {
      return
    }

    setServiceTOData([])
    ;(async () => {
      try {
        setLoading(true)

        await getServiceTO(carData.carId ?? 0, values.dealer).then(response => {
          setServiceTOData(response)
          if (carCenterData && carCenterData.to && carData.carId) {
            setActiveServiceTOOption({
              label: getServiceTOOptionById(response, carCenterData.to).label,
              value: String(carCenterData.to),
            })
            setFieldValue('to', carCenterData.to)
          } else {
            setActiveServiceTOOption(getServiceTOOptions(response)[0])
            setFieldValue('to', '')
          }
        })

        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    })()
  }, [values.dealer])

  useUpdateEffect(() => {
    if (isOnlineServiceDisabled || !values.to || !carData?.carId) {
      return
    }

    setServiceTODetailsData(null)
    ;(async () => {
      try {
        setLoading(true)

        await getServiceTODetails(
          carData.carId ?? 0,
          values.to ?? 0,
          values.dealer,
        ).then(response => {
          const isRequiredAndRecommendedTO =
            response &&
            response.summary.summ.required &&
            response.summary.summ.recommended

          setServiceTODetailsData(response)

          if (isRequiredAndRecommendedTO) {
            setActiveWorkType(true)
            setFieldValue('recommended', true)
          }

          if (!carCenterData?.comment) {
            setFieldValue('comment', '')
          }

          setLoading(false)
        })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    })()
  }, [carData?.carId, values.to])

  const handleChangeDealerSelect = (option: OptionType) => {
    if (carCenterData) {
      if (carCenterData.comment) {
        setServiceChooseCarCenterDataAction({
          ...initialServiceChooseCarCenterFormValues,
          comment: carCenterData.comment,
        })
      } else {
        setServiceChooseCarCenterDataAction(null)
      }
    }

    setActiveDealerOption(option)
    setFieldValue('dealer', getDealerId(brands.data, option.value))

    setRecordingDate(null)
    setFieldValue('date', '')

    setServiceTime(null)
    setFieldValue('time', '')

    setFieldValue('recommended', false)

    countGetTimeForTo.current = 0
  }

  const handleChangeTOSelect = (option: OptionType) => {
    const toId = getServiceTOIdByName(serviceTOData, option.value)

    setActiveServiceTOOption(option)
    setFieldValue('to', toId)

    setRecordingDate(null)
    setFieldValue('date', '')

    setServiceTime(null)
    setFieldValue('time', '')

    setFieldValue('recommended', false)

    setServiceTODetailsData(null)

    if (!toId && !carCenterData?.comment) {
      setFieldValue('comment', '')
    } else {
      setFieldValue('comment', carCenterData?.comment)
    }

    countGetTimeForTo.current = 0
  }

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {isLoading && <Spinner absolute={true} isCabinet={true} />}

      <FormInnerWrapper isLoading={isLoading}>
        <CabinetFormTemplate title={'Выбор автоцентра'}>
          <FormRow>
            <FormCol>
              <CabinetFormField
                label={'Автоцентр'}
                error={
                  errors.dealer && touched.dealer ? errors.dealer : undefined
                }
              >
                <CabinetSelect
                  value={activeDealerOption}
                  options={dealerOptions}
                  onChange={handleChangeDealerSelect}
                />
              </CabinetFormField>
            </FormCol>
          </FormRow>

          {serviceTOData && serviceTOData.length > 1 && (
            <React.Fragment>
              <FormRow>
                <FormCol>
                  <CabinetFormField
                    label={'Вид работ'}
                    error={errors.to && touched.to ? errors.to : undefined}
                  >
                    <CabinetSelect
                      value={activeServiceTOOption ?? DefaultOption}
                      options={
                        getServiceTOOptions(serviceTOData) ?? [DefaultOption]
                      }
                      onChange={handleChangeTOSelect}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              {isRequiredAndRecommendedTO && values.to ? (
                <FormRow>
                  <FormCol>
                    <CabinetFormField
                      label={'Выберите тип работ'}
                      error={errors.recommended}
                    >
                      <CabinetTabs
                        list={workTypes}
                        activeValue={activeWorkType}
                        setActiveValue={(value: boolean) => {
                          setActiveWorkType(value)
                          setFieldValue('recommended', value)
                          countGetTimeForTo.current = 0
                        }}
                      />
                    </CabinetFormField>
                  </FormCol>
                </FormRow>
              ) : null}

              {values.to && serviceTODetailsData && (
                <ServiceDetails
                  detailsData={serviceTODetailsData}
                  workType={
                    isRequiredAndRecommendedTO
                      ? activeWorkType
                        ? ServiceWorkTypes.REQUIRED_AND_RECOMMENDED
                        : ServiceWorkTypes.REQUIRED
                      : ServiceWorkTypes.REQUIRED
                  }
                />
              )}
            </React.Fragment>
          )}

          <FormRowWrapper>
            <FormRow>
              <FormCol>
                <CabinetFormField
                  label={'Дата'}
                  error={errors.date && touched.date ? errors.date : undefined}
                >
                  <CabinetDataInput
                    inputName='date'
                    data={recordingDate}
                    setData={(value: Nullable<Date>) => {
                      setRecordingDate(value)
                      setFieldValue('date', moment(value).format('YYYY-MM-DD'))

                      setServiceTime(null)
                      setFieldValue('time', '')
                    }}
                    minDate={getDayAfterTomorrowDate()}
                  />
                  <FormNote>
                    <span>*</span> Дата записи является предварительной
                  </FormNote>
                </CabinetFormField>
              </FormCol>

              <FormCol>
                {serviceTime && serviceTime.length > 0 && (
                  <CabinetFormField
                    label={'Время'}
                    error={
                      errors.time && touched.time ? errors.time : undefined
                    }
                  >
                    <CabinetTextInput
                      readOnly
                      id='time'
                      name='time'
                      value={values.time}
                      onChange={handleChange}
                      badge={<TimeIcon />}
                      onClick={() => setIsShowTimePicker(!isShowTimePicker)}
                    />

                    {isShowTimePicker && (
                      <StyledTimePicker
                        value={values.time ?? ''}
                        times={serviceTime}
                        onClick={value => {
                          setFieldValue('time', value)
                          setIsShowTimePicker(false)
                        }}
                        onClose={() => setIsShowTimePicker(false)}
                      />
                    )}
                  </CabinetFormField>
                )}
              </FormCol>
            </FormRow>
          </FormRowWrapper>

          <FormRow>
            <FormCol>
              <CabinetFormField
                label={'Комментарий'}
                error={
                  errors.comment && touched.comment ? errors.comment : undefined
                }
              >
                <CabinetTextArea
                  id='comment'
                  name='comment'
                  value={values.comment}
                  onChange={handleChange}
                />
                <FormNote>
                  При необходимости, укажите примечания и пояснения
                </FormNote>
              </CabinetFormField>
            </FormCol>
          </FormRow>

          <FormAction mobileActionReverse>
            <FormActionCol>
              <CabinetButton variant={'contained'} onClick={onPreviousStep}>
                Назад
              </CabinetButton>
            </FormActionCol>
            <FormActionCol>
              <CabinetButton
                type='submit'
                variant={'contained'}
                disabled={isDisabledSubmitButton}
              >
                Завершить
              </CabinetButton>
            </FormActionCol>
          </FormAction>
        </CabinetFormTemplate>
      </FormInnerWrapper>
    </Component>
  )
}

export default ServiceChooseCarCenterForm

const Component = styled.form`
  position: relative;
`

const FormInnerWrapper = styled.div<{ isLoading?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const FormRowWrapper = styled.div`
  padding-top: 17px;

  &:before {
    content: '';
    display: block;
    border-top: 1px solid ${props => props.theme.gray200};
    padding-bottom: 17px;
    margin: 0 16px;

    ${media.mobile(css`
      margin: 15px 16px 0;
    `)}
  }
`

const StyledTimePicker = styled(TimePicker)`
  width: calc(100% - 32px);
  margin: 0 16px;

  ${media.mobile(css`
    width: 100%;
    margin: 0;
    top: -40px;
  `)}
`
