import { ServiceChooseCarFormFields } from './types'

import {
  CabinetBrandsPayload,
  ServiceCarsPayload,
} from '@/services/users/typings'
import { Nullable, OptionType } from '@/typings/common'
import { DefaultOption } from '@/modules/PersonalData/utils'

export const getCarOptions = (cars: ServiceCarsPayload[]): OptionType[] => {
  const helpOptions = [
    { value: 'Выберите ваше авто', label: 'Выберите ваше авто' },
    { value: 'Ввести вручную', label: 'Ввести вручную' },
  ]

  if (!cars || cars.length === 0) {
    return helpOptions
  }

  const carsOptions = cars.map(({ name }) => {
    return {
      value: name ?? '',
      label: name ?? '',
    }
  })

  return [...helpOptions, ...carsOptions]
}

export const getBrands = (brands: CabinetBrandsPayload[]): OptionType[] => {
  if (!brands || brands.length === 0) {
    return [DefaultOption]
  }

  return brands.map(({ name }) => {
    return {
      value: name ?? '',
      label: name ?? '',
    }
  })
}

export const getYears = (): OptionType[] => {
  const currentYear = new Date().getFullYear()
  let startYear = currentYear - 4
  let years = []

  while (startYear <= currentYear) {
    years.push(startYear)
    startYear++
  }

  return years.map(year => {
    return {
      value: year.toString() ?? '',
      label: year.toString() ?? '',
    }
  })
}

export const getCarId = (
  cars: ServiceCarsPayload[],
  searchCar: string,
): number => {
  if (
    !cars ||
    cars.length === 0 ||
    searchCar === 'Выберите ваше авто' ||
    searchCar === 'Ввести вручную'
  ) {
    return 0
  }

  const foundCar = cars.find(car => car.name === searchCar)

  return foundCar?.id ?? 0
}

export const getCarBrandByCarId = (
  cars: ServiceCarsPayload[],
  searchCar: string,
): number => {
  if (
    !cars ||
    cars.length === 0 ||
    searchCar === 'Выберите ваше авто' ||
    searchCar === 'Ввести вручную'
  ) {
    return 0
  }

  const carId = getCarId(cars, searchCar)

  const foundBrand = cars.find(car => car.id === carId)

  return foundBrand?.brand ?? 0
}

export const getCarIdByBrand = (
  brands: CabinetBrandsPayload[],
  searchBrand: string,
): number => {
  if (!brands || brands.length === 0) {
    return 0
  }

  const foundBrand = brands.find(brand => brand.name === searchBrand)

  return foundBrand?.id ?? 0
}

export const getInitialActiveBrandOption = (
  chooseCarData: Nullable<ServiceChooseCarFormFields>,
  brands: CabinetBrandsPayload[],
): OptionType => {
  const foundBrand = brands.find(brand => brand.id === chooseCarData?.carBrand)

  return { label: foundBrand?.name ?? '', value: foundBrand?.name ?? '' }
}

export const getInitialActiveYearOption = (
  chooseCarData: Nullable<ServiceChooseCarFormFields>,
): OptionType => {
  const carYear = chooseCarData?.carYear

  return {
    label: carYear ? String(carYear) : '',
    value: carYear ? String(carYear) : '',
  }
}

export const getInitialCarOption = (
  chooseCarData: Nullable<ServiceChooseCarFormFields>,
  cars: ServiceCarsPayload[],
): OptionType => {
  if (chooseCarData?.carId) {
    const foundCar = cars.find(car => car.id === chooseCarData.carId)

    return { value: foundCar?.name ?? '', label: foundCar?.name ?? '' }
  } else if (chooseCarData?.carBrand) {
    return { value: 'Ввести вручную', label: 'Ввести вручную' }
  } else {
    return { value: 'Выберите ваше авто', label: 'Выберите ваше авто' }
  }
}
