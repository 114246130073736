import { MenuItemType } from '@/typings/common'

export const footerMenu: Array<MenuItemType> = [
  {
    id: 2450,
    label: 'Автомобили',
    link: 'https://atlantm.by/catalog/',
    isNewTab: false,
    children: [
      {
        id: 2451,
        label: 'Jetour',
        link: '/jetour/',
        isNewTab: true,
        children: [],
      },
      {
        id: 2452,
        label: 'Volkswagen',
        link: '/catalog/volkswagen/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2453,
        label: 'Ford',
        link: '/catalog/ford/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2454,
        label: 'Kia',
        link: '/catalog/kia/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2455,
        label: 'Mazda',
        link: '/catalog/mazda/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2456,
        label: 'Land Rover',
        link: '/catalog/land_rover/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2457,
        label: 'Jaguar',
        link: '/catalog/jaguar/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2458,
        label: 'Toyota',
        link: '/catalog/toyota/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2459,
        label: 'Lexus',
        link: '/catalog/lexus/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2460,
        label: 'Geely',
        link: '/catalog/geely/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2461,
        label: 'JAC',
        link: '/jac/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2462,
        label: 'Exeed',
        link: '/exeed/',
        isNewTab: true,
        children: [],
      },
      {
        id: 2463,
        label: 'BYD',
        link: '/catalog/byd',
        isNewTab: false,
        children: [],
      },
      {
        id: 2464,
        label: 'Zeekr',
        link: '/catalog/zeekr',
        isNewTab: false,
        children: [],
      },
      {
        id: 2465,
        label: 'Li',
        link: '/catalog/li',
        isNewTab: false,
        children: [],
      },
    ],
  },
  {
    id: 2466,
    label: 'Дилеры :DEALERS:',
    link: '/dealers/',
    isNewTab: false,
    children: [],
  },
  {
    id: 2467,
    label: 'Атлант-М в Беларуси',
    link: '/atlant-m-v-belarusi',
    isNewTab: false,
    children: [
      {
        id: 2468,
        label: 'Мобильное приложение',
        link: '/application/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2469,
        label: 'Личный кабинет',
        link: '/lkabinet/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2470,
        label: 'Бонусная программа',
        link: '/bonus-program/',
        isNewTab: false,
        children: [],
      },
      {
        id: 2471,
        label: 'Книга отзывов',
        link: 'https://ekov2.atlantm.com/ru/',
        isNewTab: true,
        children: [],
      },
      {
        id: 2472,
        label: 'Юридическая информация',
        link: '/yuridicheskaya-informaciya',
        isNewTab: false,
        children: [],
      },
      {
        id: 2473,
        label: 'Гарантии',
        link: '/holding/assurance/',
        isNewTab: true,
        children: [],
      },
    ],
  },
  {
    id: 2474,
    label: 'Свяжитесь с нами :CONTACTS:',
    link: '/dealers/',
    isNewTab: false,
    children: [
      {
        id: 2475,
        label: ':PHONE:',
        link: null,
        isNewTab: false,
        children: [],
      },
      {
        id: 2476,
        label: 'online@favicon-m.by',
        link: 'mailto:online@atlantm-m.by',
        isNewTab: false,
        children: [],
      },
      {
        id: 2477,
        label: 'Заказать звонок',
        link: ':CALL:',
        isNewTab: false,
        children: [],
      },
    ],
  },
]

export const footerDisclaimer =
  '<p>Общество с ограниченной ответственностью «Управляющая компания МАХ Атлант-М», зарегистрировано 10.05.2018 Минскимгорисполкомом в ЕГР за № 193077111<br>220019, Республика Беларусь г. Минск Фрунзенский район ул. Шаранговича 22 каб. 28, УНП 193077111</p><p>Режим работы интернет-магазина&nbsp;<a href="https://favicon.by/" target="_blank">favicon.by</a>: с 08.00 до 20.30 ежедневно&nbsp;<br>Зарегистрирован в Торговом реестре Республики Беларусь 04.09.2023 № 563947<br><a href="https://favicon.by/how-to" target="_blank">Доставка и оплата</a><br><a href="https://favicon.by/personal/login" target="_blank">Личный кабинет клиента Холдинга</a><br><strong>Контакты для связи по вопросам обращения покупателей о нарушении их прав:</strong><br>Тел.:&nbsp;<a href="tel:+375333335103" target="_blank">+375 33&nbsp;333 51 03</a><br><strong>Номера контактных телефонов работников местных исполнительных и распорядительных органов:</strong><br>Отдел торговли и услуг Администрации Фрунзенского района г. Минск:<br>&nbsp;<a href="tel:+375172047384" target="_blank">+375 17 204-73-84</a>.</p><p>&nbsp;</p><p><br>&nbsp;</p>'

export enum FooterVariables {
  Dealers = ':DEALERS:',
  Contacts = ':CONTACTS:',
  Phone = ':PHONE:',
  Call = ':CALL:',
}
