import React, { PropsWithChildren, useEffect, useState } from 'react'

import { FETCH_STATUSES, Nullable } from '@/typings/common'
import {
  IBonusesData,
  IBrandsData,
  ICarsData,
  IService,
  IUserProfileData,
  IUserProfilePreview,
} from '@/providers/Profile/types'
import { ProfileContext } from '@/providers/Profile/ProfileContext'
import {
  getCabinetBrands,
  getServiceCars,
  getUserBonusesPreview,
  getUserCars,
  getUserProfile,
  getUserProfilePreview,
  userApiService,
} from '@/services/users/users-service'
import { getProfilePercentFullness } from '@/utils/common'
import cookie from '@/utils/cookie'
import { useAuth } from '@/providers/Auth'
import { stepsData } from '@/modules/Service/constants'
import { ServiceContactFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceContactForm/types'
import { ServiceChooseCarFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/types'
import { ServiceChooseCarCenterFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarCenterForm/types'

export const ProfileProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { setAuthorizedAction } = useAuth()
  // ------------------- STORE ------------------- //
  const [previewData, setPreviewData] = useState<IUserProfilePreview>({
    status: FETCH_STATUSES.IDLE,
    data: null,
  })
  const [profileData, setProfileData] = useState<IUserProfileData>({
    status: FETCH_STATUSES.IDLE,
    data: null,
  })
  const [bonusesData, setBonusesData] = useState<IBonusesData>({
    status: FETCH_STATUSES.IDLE,
    data: null,
  })
  const [carsData, setCarsData] = useState<ICarsData>({
    status: FETCH_STATUSES.IDLE,
    data: [],
  })
  const [brandsData, setBrandsData] = useState<IBrandsData>({
    status: FETCH_STATUSES.IDLE,
    data: [],
  })
  const [pageLoading, setPageLoading] = React.useState(false)

  const [service, setService] = useState<IService>({
    activeStep: stepsData.steps[0],

    contactsData: null,
    chooseCarData: null,
    chooseCarCenterData: null,
    isServiceFormSubmit: false,

    cars: [],
  })

  // ------------------- ACTIONS ------------------- //

  const updatePageLoading = React.useCallback((value: boolean) => {
    setPageLoading(value)
  }, [])

  const getProfilePreviewAction = async (): Promise<void> => {
    try {
      const userProfilePreview = await getUserProfilePreview()
      setPreviewData({
        data: userProfilePreview,
        status: FETCH_STATUSES.SUCCESS,
      })
    } catch (error) {
      setProfileData(prev => ({
        data: prev.data,
        status: FETCH_STATUSES.FAILURE,
      }))
      return Promise.reject(error)
    }
  }

  const getProfileDataAction = async (): Promise<void> => {
    try {
      const personalData = await getUserProfile()

      // update token
      const tokenNew = personalData?.token ?? ''
      userApiService.setAccessToken(tokenNew)

      if (personalData) {
        setProfileData({
          status: FETCH_STATUSES.SUCCESS,
          data: {
            ...personalData,
            profilePercentFullness: getProfilePercentFullness(personalData),
          },
        })
      } else {
        setProfileData(prev => ({
          data: prev.data,
          status: FETCH_STATUSES.IDLE,
        }))
        return
      }
    } catch (error) {
      setProfileData(prev => ({
        data: prev.data,
        status: FETCH_STATUSES.FAILURE,
      }))
      return Promise.reject(error)
    }
  }

  const getBonusesDataAction = async () => {
    try {
      setBonusesData(prev => ({ ...prev, status: FETCH_STATUSES.LOADING }))
      const bonuses = await getUserBonusesPreview()

      if (bonuses) {
        setBonusesData({ status: FETCH_STATUSES.SUCCESS, data: bonuses })
      } else {
        setBonusesData(prev => ({ ...prev, status: FETCH_STATUSES.FAILURE }))
        return
      }
    } catch (error) {
      setBonusesData(prev => ({ ...prev, status: FETCH_STATUSES.FAILURE }))
      return Promise.reject(error)
    }
  }

  const getUserCarsAction = async () => {
    setCarsData(prev => ({ ...prev, status: FETCH_STATUSES.SUCCESS }))
    try {
      const cars = await getUserCars().then(response =>
        response.sort((firstCar, secondCar) => {
          const firstOwner = firstCar.isOwner
          const secondOwner = secondCar.isOwner

          return firstOwner === secondOwner ? 0 : firstOwner ? -1 : 1
        }),
      )

      setCarsData({ status: FETCH_STATUSES.SUCCESS, data: cars })
    } catch (error) {
      setCarsData({ status: FETCH_STATUSES.FAILURE, data: [] })
      return Promise.reject(error)
    }
  }

  const getBrandsAction = async () => {
    try {
      const brands = await getCabinetBrands()

      setBrandsData({ data: brands, status: FETCH_STATUSES.SUCCESS })
    } catch (error) {
      setBrandsData({ data: [], status: FETCH_STATUSES.FAILURE })
      return Promise.reject(error)
    }
  }

  const setServiceActiveStepAction = (value: string) => {
    setService(prevState => ({ ...prevState, activeStep: value }))
  }

  const setServiceContactsDataAction = (
    value: Nullable<ServiceContactFormFields>,
  ) => {
    setService(prevState => ({ ...prevState, contactsData: value }))
  }

  const setServiceChooseCarDataAction = (
    value: Nullable<ServiceChooseCarFormFields>,
  ) => {
    setService(prevState => ({ ...prevState, chooseCarData: value }))
  }

  const setServiceChooseCarCenterDataAction = (
    value: Nullable<ServiceChooseCarCenterFormFields>,
  ) => {
    setService(prevState => ({ ...prevState, chooseCarCenterData: value }))
  }

  const setServiceFormSubmitAction = (value: boolean) => {
    setService(prevState => ({ ...prevState, isServiceFormSubmit: value }))
  }

  const getServiceCarsAction = async () => {
    try {
      const cars = await getServiceCars()
      setService(prevState => ({ ...prevState, cars: cars }))
    } catch (error) {
      setService(prevState => ({ ...prevState, cars: [] }))
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    const token = cookie.get(
      process.env.REACT_APP_COOKIE_ACCESS_TOKEN_PARAM ?? 'accessToken',
    )
    if (token) {
      userApiService.setAccessToken(token)
      setPageLoading(true)

      Promise.all([getProfileDataAction(), getProfilePreviewAction()]).then(
        () => {
          setPageLoading(false)
          setAuthorizedAction()
        },
      )
    }
  }, [setAuthorizedAction])

  return (
    <ProfileContext.Provider
      value={{
        pageLoading,
        profile: profileData,
        preview: previewData,
        bonuses: bonusesData,
        cars: carsData,
        brands: brandsData,
        service: service,

        updatePageLoading,
        getProfilePreviewAction,
        getProfileDataAction,
        getBonusesDataAction,
        getUserCarsAction,
        getBrandsAction,
        setServiceActiveStepAction,
        setServiceContactsDataAction,
        setServiceChooseCarDataAction,
        setServiceChooseCarCenterDataAction,
        setServiceFormSubmitAction,
        getServiceCarsAction,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileProvider
