import React, { useLayoutEffect } from 'react'

import Cabinet from '@/modules/Cabinet/Cabinet'
import PersonalBlock from '@/modules/PersonalData/components/PersonalBlock'
import ProgressBar from '@/modules/PersonalData/components/ProgressBar'
import InformationBlock from '@/modules/PersonalData/components/InformationBlock'
import { useProfile } from '@/providers/Profile'
import CabinetError from '@/modules/Cabinet/components/CabinetError'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

function PersonalData() {
  useDocumentTitle('Кабинет Атлант-М - Личные данные')
  const { pageLoading, getBonusesDataAction, updatePageLoading } = useProfile()

  useLayoutEffect(() => {
    async function fetchData() {
      await Promise.resolve(getBonusesDataAction())
    }
    updatePageLoading(true)
    fetchData()
      .catch(error => {
        console.log(error)
      })
      .finally(() => updatePageLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Cabinet activeTab={'HOME'}>
      {!pageLoading ? (
        <>
          <PersonalBlock />
          <ProgressBar />
          <InformationBlock />
        </>
      ) : (
        <CabinetError text='Ошибка получения профиля' />
      )}
    </Cabinet>
  )
}

export default PersonalData
