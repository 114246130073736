import React from 'react';

import { Component } from './CabinetLabel.style';
import { CabinetLabelProps } from './types';

function CabinetLabel({ label }: CabinetLabelProps) {
  return <Component>{label}</Component>;
}

export default CabinetLabel;
