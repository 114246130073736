import styled from '@emotion/styled'

export const Component = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
`
