import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Cabinet from '@/modules/Cabinet'
import { CabinetDataTable } from '@/modules/Cabinet/components/CabinetDataTable'
import { getUserRequests } from '@/services/users/users-service'
import RequestsSpareCard from '@/modules/Cabinet/components/Cards/RequestsSpareCard'
import { media } from '@/utils/mixin'
import CabinetDataTabs from '@/modules/Cabinet/components/CabinetDataTabs'
import { TABS_LIST } from '@/modules/Requests/constants'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

function Requests() {
  useDocumentTitle('Кабинет Атлант-М - Мои заявки')
  const [activeTabName, setActiveTabName] = React.useState<string>(
    TABS_LIST[0].value,
  )
  return (
    <Cabinet activeTab={'REQUESTS'}>
      <Wrapper>
        <CabinetDataTabs
          tabs={TABS_LIST}
          activeTabName={activeTabName}
          setActiveTabName={(value: string) => setActiveTabName(value)}
        />
        <TabsContent>
          <CabinetDataTable
            dataFetch={() => getUserRequests()}
            rowComponent={RequestsSpareCard}
            title={'Проверьте статус ваших заказов'}
          />
        </TabsContent>
      </Wrapper>
    </Cabinet>
  )
}

export default Requests

const Wrapper = styled.div`
  padding: 30px 0;

  ${media.tabletSmall(css`
    padding: 0;
  `)}

  ${media.mobile(css`
    overflow: hidden;
  `)}
`

const TabsContent = styled.div`
  background: ${props => props.theme.white};
`
