import * as Yup from 'yup'

import { ServiceContactFormFields } from './types'

import { emailRegexp, phoneRegexp } from '@/utils/textInput'

export const initialServiceContactFormValues: ServiceContactFormFields = {
  surname: '',
  name: '',
  patronymic: '',
  email: '',
  phone: '',
}

export const validationServiceContactFormSchema: Yup.Schema<ServiceContactFormFields> =
  Yup.object().shape({
    surname: Yup.string().required('Укажите фамилию'),
    name: Yup.string().required('Укажите имя'),
    patronymic: Yup.string().required('Укажите отчество'),
    phone: Yup.string()
      .label('Phone')
      .required('Укажите номер телефона')
      .matches(phoneRegexp, 'Телефон введен неверно'),
    email: Yup.string()
      .label('Email')
      .matches(emailRegexp, 'Email введен неверно'),
  })
