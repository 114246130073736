import React, { useState } from 'react'

import {
  Field,
  FieldInner,
  Plus,
  Text,
} from '@/modules/PersonalData/components/InformationBlock/components/AddEmail/common.styles'
import AddEmailModal from '@/components/modals/AddEmailModal'

function AddEmail() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Field>
      <FieldInner onClick={() => setIsOpen(true)}>
        <Plus />
        <Text>Добавить Email</Text>
      </FieldInner>
      <AddEmailModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </Field>
  )
}

export default AddEmail
