import { OptionType } from '@/typings/common';

export const fuelTypesOptions: OptionType<null | number>[] = [
  { label: 'Выберите тип топлива', value: null },
  { label: 'Бензин', value: 1 },
  { label: 'Дизель', value: 2 },
  { label: 'Гибрид', value: 3 },
  { label: 'Электро', value: 4 },
  { label: 'Газ-бензин', value: 9 },
];

export const gearboxTypes: OptionType<null | number>[] = [
  { label: 'Выберите КПП', value: null },
  { label: 'Механическая', value: 1 },
  { label: 'Автоматическая', value: 4 },
  { label: 'DSG', value: 11 },
  { label: 'Робот', value: 12 },
  { label: 'Вариатор', value: 13 },
  { label: 'Редуктор', value: 14 },
];
