import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import carIcon from '@/assets/images/car.png'
import useMedia from '@/hooks/useMedia'

interface Props {
  percent: number
}

function CarTracks({ percent }: Props) {
  const isMobile = useMedia('(max-width: 767px)')

  return (
    <Track>
      <CarImage loading='lazy' src={carIcon} percent={percent} />
      <Inner percent={percent}>
        <TotalPoint percent={percent} />
        <Point>
          <PointPercent>0%</PointPercent>
        </Point>
        {isMobile ? (
          <Point>
            <PointPercent>50%</PointPercent>
          </Point>
        ) : (
          <>
            <Point>
              <PointPercent>20%</PointPercent>
            </Point>
            <Point>
              <PointPercent>40%</PointPercent>
            </Point>
            <Point>
              <PointPercent>60%</PointPercent>
            </Point>
            <Point>
              <PointPercent>80%</PointPercent>
            </Point>
          </>
        )}
        <Point>
          <PointPercent isLast={true}>100%</PointPercent>
        </Point>
      </Inner>
    </Track>
  )
}

const Track = styled.div`
  width: 100%;
  position: relative;

  ${media.mobile(css`
    padding-bottom: 10px;
  `)}
`

const CarImage = styled.img<{ percent: number }>`
  width: 100px;
  position: absolute;
  bottom: 10px;
  margin-left: -50px;
  left: ${props => (props.percent ? props.percent : 0)}%;

  ${media.mobile(css`
    bottom: 5px;
  `)}

  img {
    width: 100px;
  }
`

const Inner = styled.div<{ percent: number }>`
  position: relative;

  display: flex;
  justify-content: space-between;
  width: 100%;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to right,
      ${props => props.theme.gray500} 10%,
      rgba(255, 255, 255, 0) 0
    );
    background-position: top;
    background-size: 34.5px 50px;
    background-repeat: repeat-x;
    padding: 1px;
  }

  &:after {
    content: '';
    width: ${props => (props.percent ? props.percent : 0)}%;
    height: 2px;
    background: #c7deff;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`

const TotalPoint = styled.div<{ percent: number }>`
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${props => props.theme.white};
  border-radius: 50%;
  border: 2px solid #c7deff;
  position: absolute;
  top: 0;
  left: ${props => (props.percent ? props.percent : 0)}%;
  transform: translateY(-40%);
  z-index: 2;
`

const Point = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${props => props.theme.main};
    border-radius: 50%;
    position: absolute;
    top: -3px;
    left: auto;
    z-index: 1;
  }
`

const PointPercent = styled.span<{ isLast?: boolean }>`
  position: absolute;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.gray500};
  top: 5px;
  left: auto;

  ${props =>
    props.isLast &&
    css`
      margin-left: -28px;
    `}
`

export default CarTracks
