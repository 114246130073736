import React from 'react'

import * as S from './CabinetTextArea.style'

type Props = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'prefix'
> & {
  className?: string
  prefix?: React.ElementType<any, keyof React.JSX.IntrinsicElements> | undefined
  invalid?: boolean
}

export type TextAreaRef = HTMLTextAreaElement

function CabinetTextArea({
  className,
  prefix,
  disabled,
  invalid,
  ...rest
}: Props) {
  function renderPrefix() {
    if (!prefix) return null
    if (React.isValidElement(prefix)) return prefix
    return <S.Prefix as={prefix} />
  }

  return (
    <S.Container className={className}>
      {renderPrefix()}
      <S.TextArea
        {...rest}
        withPrefix={Boolean(prefix)}
        disabled={disabled}
        invalid={invalid}
      />
    </S.Container>
  )
}

export default React.forwardRef<TextAreaRef, Props>(CabinetTextArea)
