import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import moment from 'moment'

import { media } from '@/utils/mixin'
import { UserBonusesItemType } from '@/services/users/typings'

interface Props {
  card: UserBonusesItemType
}

function BonusDataCard({ card }: Props) {
  const { date, dealer, name, amount } = card

  return (
    <Card>
      <Inner>
        <Left>
          <Date>{moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</Date>
          <Dealer>{dealer}</Dealer>
          <Description>{name}</Description>
        </Left>
        <Right>
          <Value isCosts={amount < 0}>
            {amount < 0 ? `${amount}` : `+${amount}`}
          </Value>
        </Right>
      </Inner>
    </Card>
  )
}

const Card = styled.div`
  padding: 30px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
    height: 1px;
    background-color: ${props => props.theme.gray600};
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tabletSmall(css`
    display: block;
  `)}
`

const Left = styled.div`
  display: flex;
  align-items: center;

  ${media.tabletSmall(css`
    display: block;
    margin-right: 50px;
  `)}
`

const Right = styled.div`
  margin-left: 30px;

  ${media.tabletSmall(css`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  `)}
`

const Date = styled.div`
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};

  ${media.tabletSmall(css`
    margin: 0;
  `)}
`

const Dealer = styled.div`
  margin-right: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  ${media.tabletSmall(css`
    margin: 0;
  `)}
`

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.gray500};
`

const Value = styled.div<{ isCosts: boolean }>`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.green};

  ${({ isCosts, theme }) =>
    isCosts &&
    css`
      color: ${theme.red_1};
    `}
`

export default BonusDataCard
