// @ts-ignore
import { IMaskInput } from 'react-imask'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import {
  FormAction,
  FormNote,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'

export const Component = styled.form`
  padding: 20px;
  width: calc(100vw - 20px);
  max-width: 730px;
  border-radius: 10px;
  background: ${props => props.theme.white};

  ${media.mobile(css`
    padding: 0;
  `)}
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: ${props => props.theme.main};

  ${media.mobile(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`

export const Brand = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;

  ${media.mobile(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`

export const Vin = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const FormWrapper = styled.div`
  padding: 17px 0;

  ${FormNote} {
    padding: 0;
    margin-top: 5px;
  }

  ${FormAction} {
    align-items: center;
    justify-content: center;
  }
`

export const CabinetTextInputPhone = styled(IMaskInput)`
  ${InputStyles}
`
