import React, { useState } from 'react'

import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import CarValuationModal from '@/components/modals/CarValuationModal'
import { CarsItemType } from '@/services/users/typings'
import { IBrandsData } from '@/providers/Profile/types'
import { useProfile } from '@/providers/Profile'
import { CarValuationFormModalFields } from '@/components/PersonalCabinetForms/modals/CarValuationFormModal/types'
import { submitCabinetCarValuation } from '@/services/leads/leads-service'

interface Props {
  car: CarsItemType
  brands: IBrandsData
}

function CarValueModalButton({ car, brands }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const { profile } = useProfile()

  const sendCarValuationForm = async (
    values: CarValuationFormModalFields,
  ): Promise<void> => {
    try {
      await submitCabinetCarValuation({
        ...values,
        mileage: values.mileage ? Number(values.mileage) : null,
        fuel: values.fuel ? Number(values.fuel) : null,
        gearbox: values.gearbox ? Number(values.gearbox) : null,
        brand: car.brandName ?? '',
        model: car.modelName ?? '',
        year: car.year,
      })
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
  return (
    <>
      <CabinetButton variant={'contained'} onClick={() => setIsOpen(true)}>
        Оценить авто
      </CabinetButton>
      <CarValuationModal
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        car={car}
        personalData={profile.data}
        brands={brands.data}
        onSubmit={sendCarValuationForm}
      />
    </>
  )
}

export default CarValueModalButton
