import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { getServiceTOPartsAndWorks } from './helpers'
import { ServiceDetailsProps, ServiceWorkTypes } from './types'

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down-cabinet.svg'
import { getHoursAndMinutes } from '@/modules/PersonalData/utils'
import { media } from '@/utils/mixin'

function ServiceDetails({
  detailsData,
  workType = ServiceWorkTypes.REQUIRED,
}: ServiceDetailsProps) {
  const [isOpenedRequiredCard, setOpenedRequiredCard] = useState(false)
  const [isOpenedRecommendedCard, setOpenedRecommendedCard] = useState(false)

  const requiredWorksTime = getHoursAndMinutes(
    Number(detailsData?.summary.time.required),
  )
  const recommendedWorksTime = getHoursAndMinutes(
    Number(detailsData?.summary.time.recommended),
  )
  const summaryWorksTime = getHoursAndMinutes(
    Number(detailsData?.summary.time.total),
  )

  const isRequiredOrAllWorks =
    workType === ServiceWorkTypes.REQUIRED ||
    workType === ServiceWorkTypes.REQUIRED_AND_RECOMMENDED

  const isRecommendedOrAllWorks =
    workType === ServiceWorkTypes.RECOMMENDED ||
    workType === ServiceWorkTypes.REQUIRED_AND_RECOMMENDED

  const requiredParts = getServiceTOPartsAndWorks(
    detailsData,
    'parts',
    'required',
  )
  const requiredWorks = getServiceTOPartsAndWorks(
    detailsData,
    'works',
    'required',
  )
  const recommendedParts = getServiceTOPartsAndWorks(
    detailsData,
    'parts',
    'recommended',
  )
  const recommendedWorks = getServiceTOPartsAndWorks(
    detailsData,
    'works',
    'recommended',
  )

  return (
    <Component>
      {isRequiredOrAllWorks && (
        <DetailsCard>
          <CardHeader
            onClick={() => setOpenedRequiredCard(!isOpenedRequiredCard)}
          >
            <CardTitle>Обязательные работы</CardTitle>
            <StyledArrowIcon isOpenedCard={isOpenedRequiredCard} />
          </CardHeader>
          <CardBody>
            <CardBodyItem>
              <CardBodyItemTitle>Работы займут</CardBodyItemTitle>
              <CardBodyItemValue>
                {requiredWorksTime.hours ? (
                  <React.Fragment>
                    {requiredWorksTime.hours}{' '}
                    <CardBodyItemPrefix>час (а)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null}

                {requiredWorksTime.minutes ? (
                  <React.Fragment>
                    {requiredWorksTime.minutes}{' '}
                    <CardBodyItemPrefix>минут (ы)</CardBodyItemPrefix>
                  </React.Fragment>
                ) : null}
              </CardBodyItemValue>
            </CardBodyItem>

            <CardBodyItem>
              <CardBodyItemTitle>Стоимость составит</CardBodyItemTitle>
              <CardBodyItemValue>
                {detailsData?.summary.summ.required}{' '}
                <CardBodyItemPrefix>руб</CardBodyItemPrefix>
              </CardBodyItemValue>
            </CardBodyItem>
          </CardBody>

          {isOpenedRequiredCard && (
            <CardInfo>
              {requiredWorks && requiredWorks.length > 0 && (
                <CardInfoItem>
                  <CardInfoItemTitle>Перечень работ</CardInfoItemTitle>
                  <CardInfoList>
                    {requiredWorks.map(
                      ({ summ, name, units, count }, index) => (
                        <CardInfoListItem key={index}>
                          <CardInfoListItemTitle>{name}</CardInfoListItemTitle>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Количество
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {count / 3600} ч.
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Стоимость
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {summ.total}{' '}
                              {summ.currency ? summ.currency : 'BYN'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                        </CardInfoListItem>
                      ),
                    )}
                  </CardInfoList>
                </CardInfoItem>
              )}

              {requiredParts && requiredParts.length > 0 && (
                <CardInfoItem>
                  <CardInfoItemTitle>Перечень запчастей</CardInfoItemTitle>
                  <CardInfoList>
                    {requiredParts.map(
                      ({ summ, name, units, count }, index) => (
                        <CardInfoListItem key={index}>
                          <CardInfoListItemTitle>{name}</CardInfoListItemTitle>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Количество
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {count} {units ? units : 'шт'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Стоимость
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {summ.total}{' '}
                              {summ.currency ? summ.currency : 'BYN'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                        </CardInfoListItem>
                      ),
                    )}
                  </CardInfoList>
                </CardInfoItem>
              )}
            </CardInfo>
          )}
        </DetailsCard>
      )}

      {isRecommendedOrAllWorks && (
        <DetailsCard>
          <CardHeader
            onClick={() => setOpenedRecommendedCard(!isOpenedRecommendedCard)}
          >
            <CardTitle>Рекомендуемые работы</CardTitle>
            <StyledArrowIcon isOpenedCard={isOpenedRecommendedCard} />
          </CardHeader>
          <CardBody>
            <CardBodyItem>
              <CardBodyItemTitle>Работы займут</CardBodyItemTitle>
              <CardBodyItemValue>
                {recommendedWorksTime.hours ? (
                  <React.Fragment>
                    {recommendedWorksTime.hours}{' '}
                    <CardBodyItemPrefix>час (а)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null}

                {recommendedWorksTime.minutes ? (
                  <React.Fragment>
                    {recommendedWorksTime.minutes}{' '}
                    <CardBodyItemPrefix>минут (ы)</CardBodyItemPrefix>
                  </React.Fragment>
                ) : null}
              </CardBodyItemValue>
            </CardBodyItem>

            <CardBodyItem>
              <CardBodyItemTitle>Стоимость составит</CardBodyItemTitle>
              <CardBodyItemValue>
                {detailsData?.summary.summ.recommended}{' '}
                <CardBodyItemPrefix>руб</CardBodyItemPrefix>
              </CardBodyItemValue>
            </CardBodyItem>
          </CardBody>

          {isOpenedRecommendedCard && (
            <CardInfo>
              {recommendedWorks && recommendedWorks.length > 0 && (
                <CardInfoItem>
                  <CardInfoItemTitle>Перечень работ</CardInfoItemTitle>
                  <CardInfoList>
                    {recommendedWorks.map(
                      ({ summ, name, units, count }, index) => (
                        <CardInfoListItem key={index}>
                          <CardInfoListItemTitle>{name}</CardInfoListItemTitle>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Количество
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {count / 3600} ч.
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Стоимость
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {summ.total}{' '}
                              {summ.currency ? summ.currency : 'BYN'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                        </CardInfoListItem>
                      ),
                    )}
                  </CardInfoList>
                </CardInfoItem>
              )}

              {recommendedParts && recommendedParts.length > 0 && (
                <CardInfoItem>
                  <CardInfoItemTitle>Перечень запчастей</CardInfoItemTitle>
                  <CardInfoList>
                    {recommendedParts.map(
                      ({ summ, name, units, count }, index) => (
                        <CardInfoListItem key={index}>
                          <CardInfoListItemTitle>{name}</CardInfoListItemTitle>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Количество
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {count} {units ? units : 'шт'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                          <CardInfoListItemRow>
                            <CardInfoListItemRowTitle>
                              Стоимость
                            </CardInfoListItemRowTitle>
                            <CardInfoListItemRowValue>
                              {summ.total}{' '}
                              {summ.currency ? summ.currency : 'BYN'}
                            </CardInfoListItemRowValue>
                          </CardInfoListItemRow>
                        </CardInfoListItem>
                      ),
                    )}
                  </CardInfoList>
                </CardInfoItem>
              )}
            </CardInfo>
          )}
        </DetailsCard>
      )}

      <DetailsCard>
        <CardTitle>Итого</CardTitle>
        <CardBody>
          <CardBodyItem>
            <CardBodyItemTitle>Работы займут</CardBodyItemTitle>
            <CardBodyItemValue>
              {workType === ServiceWorkTypes.REQUIRED ? (
                requiredWorksTime.hours ? (
                  <React.Fragment>
                    {requiredWorksTime.hours}{' '}
                    <CardBodyItemPrefix>час (а)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null
              ) : workType === ServiceWorkTypes.RECOMMENDED ? (
                requiredWorksTime.hours ? (
                  <React.Fragment>
                    {requiredWorksTime.hours}{' '}
                    <CardBodyItemPrefix>час (а)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null
              ) : summaryWorksTime.hours ? (
                <React.Fragment>
                  {summaryWorksTime.hours}{' '}
                  <CardBodyItemPrefix>час (а)</CardBodyItemPrefix>{' '}
                </React.Fragment>
              ) : null}

              {workType === ServiceWorkTypes.REQUIRED ? (
                requiredWorksTime.minutes ? (
                  <React.Fragment>
                    {requiredWorksTime.minutes}{' '}
                    <CardBodyItemPrefix>минут (ы)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null
              ) : workType === ServiceWorkTypes.RECOMMENDED ? (
                requiredWorksTime.minutes ? (
                  <React.Fragment>
                    {requiredWorksTime.minutes}{' '}
                    <CardBodyItemPrefix>минут (ы)</CardBodyItemPrefix>{' '}
                  </React.Fragment>
                ) : null
              ) : summaryWorksTime.minutes ? (
                <React.Fragment>
                  {summaryWorksTime.minutes}{' '}
                  <CardBodyItemPrefix>минут (ы)</CardBodyItemPrefix>{' '}
                </React.Fragment>
              ) : null}
            </CardBodyItemValue>
          </CardBodyItem>

          <CardBodyItem>
            <CardBodyItemTitle>Стоимость составит</CardBodyItemTitle>
            <CardBodyItemValue>
              {workType === ServiceWorkTypes.REQUIRED
                ? detailsData?.summary.summ.required
                : workType === ServiceWorkTypes.RECOMMENDED
                  ? detailsData?.summary.summ.recommended
                  : detailsData?.summary.summ.total}{' '}
              <CardBodyItemPrefix>руб</CardBodyItemPrefix>
            </CardBodyItemValue>
          </CardBodyItem>
        </CardBody>
      </DetailsCard>

      <Note>
        Стоимость и время указаны оценочно. Итоговые значения могут отличаться
        от расчетных
      </Note>
    </Component>
  )
}

export default ServiceDetails

const Component = styled.div`
  padding: 45px 16px;
`

const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 10px;
  }
`

const CardHeader = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const CardTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
`

const StyledArrowIcon = styled(ArrowIcon)<{ isOpenedCard?: boolean }>`
  width: 10px;
  height: 10px;
  margin: 2px 0 0 4px;
  transition: 0.3s;

  path {
    fill: ${props => props.theme.blue100};
  }

  ${({ isOpenedCard }) =>
    isOpenedCard &&
    css`
      transform: rotate(-180deg);
    `}
`

const CardBody = styled.div`
  display: flex;
  margin-top: 10px;
`

const CardBodyItem = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-left: 30px;
  }
`

const CardBodyItemTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.gray500};
`

const CardBodyItemValue = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.blue100};

  ${media.tabletSmall(css`
    font-size: 16px;
    line-height: 24px;
  `)}
`

const CardBodyItemPrefix = styled.span`
  font-size: 16px;
  line-height: 24px;
`

const CardInfo = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  background: ${props => props.theme.gray200};

  ${media.tabletSmall(css`
    max-width: 515px;
  `)}
`

const CardInfoItem = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`

const CardInfoItemTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.gray500};
`

const CardInfoList = styled.div`
  margin-top: 8px;
`

const CardInfoListItem = styled.div`
  position: relative;
  padding-left: 20px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.main};
    top: 7px;
    left: 0;

    ${media.mobile(css`
      width: 6px;
      height: 7px;
    `)}
  }
`

const CardInfoListItemTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.blue100};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`

const CardInfoListItemRow = styled.div`
  display: flex;
  margin-top: 5px;
`

const CardInfoListItemRowTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  flex: 0 0 auto;
  width: 35%;
  color: ${props => props.theme.gray500};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`

const CardInfoListItemRowValue = styled.span`
  font-size: 16px;
  line-height: 24px;
  margin-left: 20px;
  color: ${props => props.theme.blue100};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`

const Note = styled.p`
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;
  color: ${props => props.theme.gray500};
`
