import React from 'react'

import { ModalWindowProps } from '@/components/modals/ModalWindow/types'
import ModalWindow from '@/components/modals/ModalWindow'
import AddEmailFormContainer from '@/components/PersonalCabinetForms/AddEmailForm'

export interface AddEmailModalModalProps
  extends Omit<ModalWindowProps, 'children' | 'maxWidth'> {
  closeModal: () => void
}

function AddEmailModal(props: AddEmailModalModalProps) {
  return (
    <ModalWindow {...props} onRequestClose={props.closeModal} maxWidth={360}>
      <AddEmailFormContainer closeModal={props.closeModal} />
    </ModalWindow>
  )
}

export default AddEmailModal
