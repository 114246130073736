import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import { StringField } from '@/typings/common'

interface Props {
  date: StringField
}

function CardDate({ date }: Props) {
  return <Date>{moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</Date>
}

const Date = styled.div`
  color: ${props => props.theme.gray500};
  font-size: 14px;
  line-height: 20px;
`

export default CardDate
