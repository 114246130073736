import * as Yup from 'yup'

import { RepairPartsRequestFormModalFields } from './types'

export const initialRepairPartsRequestFormModalValues: RepairPartsRequestFormModalFields =
  {
    message: '',
    dealer: 0,
  }

export const validationRepairPartsRequestFormModalSchema: Yup.Schema<RepairPartsRequestFormModalFields> =
  Yup.object().shape({
    message: Yup.string().required('Введите запчасти'),
    dealer: Yup.number()
      .required('Выберите автоцентр')
      .min(1, 'Выберите автоцентр'),
  })
