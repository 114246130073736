import React from 'react'
import styled from '@emotion/styled'

import { IDataTableRowComponents } from '../../../../types'

interface Props {
  dataList: any
  rowComponent: React.ComponentType<IDataTableRowComponents>
}

export const DataList = ({ rowComponent: Card, dataList }: Props) => {
  return (
    <List>
      {dataList?.map((card: any, index: number) => {
        return <Card card={card} key={card?.id ?? index} />
      })}
    </List>
  )
}

const List = styled.div``
