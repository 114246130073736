import React from 'react';

import { PurchasesCarsItemType } from '@/services/users/typings';
import CardTemplate from '@/modules/Cabinet/components/Cards/components/CardTemplate';
import CardTop from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardTop';
import CardDate from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardDate';
import CardBill from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardBill';
import CardPreview from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardPreview';

interface Props {
  card: PurchasesCarsItemType;
}

function PurchasesCarCard({ card }: Props) {
  const { date, number, dealer, price, currency, car, manager } = card;

  return (
    <CardTemplate isPointer={false}>
      <CardTop>
        <CardDate date={date} />
        <CardPreview boldText={'№' + number} dealer={dealer}>
          <div>{car}</div>
          {manager && <div>Менеджер · {manager}</div>}
        </CardPreview>
        <CardBill total={price} currency={currency} />
      </CardTop>
    </CardTemplate>
  );
}

export default PurchasesCarCard;
