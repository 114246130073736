import React from 'react'
import styled from '@emotion/styled'

import { ReactComponent as SuccessIcon } from '@/assets/svg/success.svg'

interface Props {
  className?: string
}

function SuccessForm({ className }: Props) {
  return (
    <Component className={className}>
      <SuccessIconWrapper>
        <SuccessIcon />
      </SuccessIconWrapper>
      <Title>Заявка успешно отправлена!</Title>
      <Text>Спасибо за обращение в Атлант-М</Text>
    </Component>
  )
}

export default SuccessForm

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.black100};
`

const SuccessIconWrapper = styled.div``

const Title = styled.span`
  margin-top: 15px;
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`
const Text = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  opacity: 0.5;
`
