import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { OptionType } from '@/typings/common'

interface Props {
  tabs: OptionType[]
  activeTabName: string
  setActiveTabName: (value: string) => void
}

function CabinetDataTabs({ tabs, activeTabName, setActiveTabName }: Props) {
  return (
    <Component>
      <List>
        {tabs?.map(tab => (
          <ListItem
            key={tab.value}
            isActive={activeTabName === tab.value}
            onClick={() => setActiveTabName(tab.value)}
          >
            {tab.label}
          </ListItem>
        ))}
      </List>
    </Component>
  )
}

const Component = styled.div``

const List = styled.ul`
  display: flex;

  ${media.tablet(css`
    overflow-x: auto;
    overflow-y: hidden;
  `)}

  ${media.mobile(css`
    box-shadow: 0 -1px 0 0 rgb(20 27 39 / 15%) inset;
  `)}
`

const ListItem = styled.li<{ isActive: boolean }>`
  flex: 1 1 auto;
  padding: 30px;
  background: transparent;
  color: ${props => props.theme.black100};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;

  ${media.tablet(css`
    flex: 1 1 33.33%;
    min-width: 33.33%;
    padding: 20px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 auto;
    min-width: auto;
    white-space: nowrap;
    cursor: default;
  `)}


  &:hover {
    color: ${props => props.theme.main};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.white};
      color: ${theme.main};
      cursor: default;

      ${media.mobile(css`
        border-bottom: 1px solid rgba(0, 97, 237, 1);
      `)}
    `}
`

export default CabinetDataTabs
