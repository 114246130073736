import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

interface Props {
  children?: React.ReactNode
}

function CardAction({ children }: Props) {
  return <Component>{children}</Component>
}

const Component = styled.div`
  flex: 0 0 340px;
  padding: 0 30px;

  ${media.mobile(css`
    margin-top: 10px;
    padding: 0;
  `)}
`

export default CardAction
