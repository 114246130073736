import React, { useState } from 'react'

import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import RepairPartsRequestModal from '@/components/modals/RepairPartsRequestModal'
import { CarsItemType } from '@/services/users/typings'
import { IBrandsData } from '@/providers/Profile/types'
import { RepairPartsRequestFormModalFields } from '@/components/PersonalCabinetForms/modals/RepairPartsRequestFormModal/types'
import { submitCabinetRequestParts } from '@/services/leads/leads-service'
import { useProfile } from '@/providers/Profile'

interface RepairPartsFormButtonProps {
  car: CarsItemType
  brands: IBrandsData
}

function RepairPartsFormButton({ car, brands }: RepairPartsFormButtonProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { profile } = useProfile()

  const sendRepairPartsRequestForm = async (
    values: RepairPartsRequestFormModalFields,
  ): Promise<void> => {
    try {
      await submitCabinetRequestParts({
        ...values,
        name: profile.data?.name ?? '',
        surname: profile.data?.lastName ?? '',
        patronymic: profile.data?.patronymic ?? '',
        phone: profile.data?.phones[0] ?? '',
        email: profile.data?.emails[0] ?? '',
        brand: car.brandName ?? '',
        model: car.modelName ?? '',
        vin: car.vin ?? '',
      })
    } catch (error: any) {
      return Promise.reject(error)
    }
  }
  return (
    <>
      <CabinetButton variant={'outlined'} onClick={() => setIsOpen(true)}>
        Заявка на запчасти
      </CabinetButton>
      <RepairPartsRequestModal
        isOpen={isOpen}
        car={car}
        brands={brands.data}
        closeModal={() => setIsOpen(false)}
        onSubmit={sendRepairPartsRequestForm}
      />
    </>
  )
}

export default RepairPartsFormButton
