import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { useCardTemplate } from '../../useCardTemplate'

import { media } from '@/utils/mixin'
import { StringField } from '@/typings/common'

interface Props {
  boldText: string
  dealer: StringField
  children?: React.ReactNode
  isIcon?: boolean
  isTabletBlock?: boolean
  textSmall?: boolean
}

function CardPreview({
  boldText,
  children,
  dealer,
  isIcon,
  isTabletBlock,
  textSmall,
}: Props) {
  const { isOpen } = useCardTemplate()

  return (
    <Preview isTabletBlock={isTabletBlock}>
      <BoldText isOpen={isIcon && isOpen}>{boldText}</BoldText>
      <Dealer>{dealer}</Dealer>
      <Text textSmall={textSmall}>{children}</Text>
    </Preview>
  )
}

const Preview = styled.div<{ isTabletBlock?: boolean }>`
  flex: 1 1 auto;
  line-height: 24px;
  padding: 0 30px;

  ${media.mobile(css`
    padding: 0;
  `)}

  ${({ isTabletBlock }) =>
    isTabletBlock &&
    css`
      ${Dealer} {
        ${media.tabletSmall(css`
          display: block;
          padding: 2px 0 0;
        `)}
      }
    `}
`

const BoldText = styled.span<{ isOpen?: boolean }>`
  font-weight: 700;
  font-size: 20px;
  color: ${props => props.theme.black100};

  ${media.mobile(css`
    display: block;
  `)}

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      color: ${theme.main};
    `}
`

const Dealer = styled.span`
  padding-left: 5px;
  font-size: 14px;
  color: ${props => props.theme.blue100};

  ${media.mobile(css`
    display: block;
    padding: 2px 0 0;
  `)}
`

const Text = styled.div<{ textSmall?: boolean }>`
  color: ${props => props.theme.gray500};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  div {
    margin-top: 5px;
  }

  ${({ textSmall, theme }) =>
    textSmall &&
    css`
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${theme.blue100};

      div {
        margin-top: 0;
      }
    `}
`

export default CardPreview
