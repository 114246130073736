import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import { fuelTypesOptions, gearboxTypes } from './constants'
import {
  CarValuationFormModalFields,
  CarValuationFormModalProps,
} from './types'
import {
  initialCarValuationFormModalValues,
  validationCarValuationFormModalSchema,
} from './helpers'

import {
  Component,
  FormWrapper,
  Title,
  CabinetTextInputPhone,
} from '@/components/PersonalCabinetForms/modals/common.styles'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetTextArea from '@/components/PersonalCabinetForms/components/CabinetTextArea'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import SuccessForm from '@/components/Forms/SuccessForm'
import { OptionType } from '@/typings/common'
import { handleValidateField, TypesOfValidate } from '@/utils/textInput'
import CabinetLabel from '@/components/PersonalCabinetForms/components/CabinetLabel'
import {
  getDealerId,
  getDealerOptions,
  getFullDealerOptions,
} from '@/modules/PersonalData/utils'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'

function CarValuationFormModal({
  car,
  brands,
  onSubmit,
  personalData,
}: CarValuationFormModalProps) {
  const [isFormSent, setFormSent] = useState(false)

  const dealerOptions = car.brand
    ? getDealerOptions(car.brand, brands)
    : getFullDealerOptions(brands)

  const [activeDealerOption, setActiveDealerOption] = useState<OptionType>(
    dealerOptions[0],
  )
  const [activeFuelTypeOption, setActiveFuelTypeOption] = useState<
    OptionType<null | number>
  >(fuelTypesOptions[0])
  const [activeGearboxTypeOption, setActiveGearboxTypeOption] = useState<
    OptionType<null | number>
  >(gearboxTypes[0])

  useLockBodyScroll()

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik<CarValuationFormModalFields>({
    initialValues: initialCarValuationFormModalValues,
    validationSchema: validationCarValuationFormModalSchema,
    onSubmit: values => {
      onSubmit?.(values).then(() => setFormSent(true))
    },
  })

  useEffect(() => {
    setValues({
      ...values,
      dealer: getDealerId(brands, activeDealerOption.value),
      name: personalData?.name ?? '',
      surname: personalData?.lastName ?? '',
      patronymic: personalData?.patronymic ?? '',
      phone: personalData?.phones[0] ?? '',
      email: personalData?.emails[0] ?? '',
      vin: car.vin ?? '',
    })
  }, [
    brands,
    car.vin,
    personalData?.name,
    personalData?.lastName,
    personalData?.emails,
    personalData?.patronymic,
    personalData?.phones,
    values,
    setValues,
    activeDealerOption.value,
  ])

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {!isFormSent ? (
        <React.Fragment>
          <Title>Заявка на оценку авто</Title>

          <FormWrapper>
            <CabinetFormTemplate title='Данные автомобиля'>
              <FormRow>
                {car.brandName && (
                  <FormCol>
                    <CabinetFormField label={'Марка'}>
                      <CabinetLabel label={car.brandName} />
                    </CabinetFormField>
                  </FormCol>
                )}

                {car.modelName && (
                  <FormCol>
                    <CabinetFormField label={'Модель'}>
                      <CabinetLabel label={car.modelName} />
                    </CabinetFormField>
                  </FormCol>
                )}

                {car.year ? (
                  <FormCol>
                    <CabinetFormField label={'Год'}>
                      <CabinetLabel label={String(car.year)} />
                    </CabinetFormField>
                  </FormCol>
                ) : null}
              </FormRow>

              <FormRow>
                <FormCol>
                  <CabinetFormField label={'VIN (необязательно)'}>
                    <CabinetTextInput
                      id='vin'
                      name='vin'
                      value={values.vin}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField label={'Пробег (необязательно)'}>
                    <CabinetTextInput
                      id='mileage'
                      name='mileage'
                      value={values.mileage ?? ''}
                      onChange={event =>
                        handleValidateField(
                          event,
                          handleChange,
                          TypesOfValidate.NUMBER,
                          7,
                        )
                      }
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormRow>
                <FormCol>
                  <CabinetFormField label={'Объем двигателя (необязательно)'}>
                    <CabinetTextInput
                      id='engineCapacity'
                      name='engineCapacity'
                      value={values.engineCapacity}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField label={'Тип топлива (необязательно)'}>
                    <CabinetSelect
                      value={activeFuelTypeOption}
                      options={fuelTypesOptions}
                      onChange={newSelectedValue => {
                        setActiveFuelTypeOption(newSelectedValue)
                        setFieldValue('fuel', newSelectedValue.value)
                      }}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormRow>
                <FormCol>
                  <CabinetFormField label={'КПП (необязательно)'}>
                    <CabinetSelect
                      value={activeGearboxTypeOption}
                      options={gearboxTypes}
                      onChange={newSelectedValue => {
                        setActiveGearboxTypeOption(newSelectedValue)
                        setFieldValue('gearbox', newSelectedValue.value)
                      }}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField label={'Автоцентр'}>
                    <CabinetSelect
                      value={activeDealerOption}
                      options={dealerOptions}
                      onChange={newSelectedValue => {
                        setActiveDealerOption(newSelectedValue)
                        setFieldValue(
                          'dealer',
                          getDealerId(brands, newSelectedValue.value),
                        )
                      }}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>
            </CabinetFormTemplate>
          </FormWrapper>

          <FormWrapper>
            <CabinetFormTemplate title='Контактные данные'>
              <FormRow>
                <FormCol>
                  <CabinetFormField
                    label={'Имя'}
                    error={
                      errors.name && touched.name ? errors.name : undefined
                    }
                  >
                    <CabinetTextInput
                      id='name'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField
                    label={'Фамилия'}
                    error={
                      errors.surname && touched.surname
                        ? errors.surname
                        : undefined
                    }
                  >
                    <CabinetTextInput
                      id='surname'
                      name='surname'
                      value={values.surname}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField
                    label={'Отчество'}
                    error={
                      errors.patronymic && touched.patronymic
                        ? errors.patronymic
                        : undefined
                    }
                  >
                    <CabinetTextInput
                      id='patronymic'
                      name='patronymic'
                      value={values.patronymic}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormRow>
                <FormCol>
                  <CabinetFormField
                    label={'Телефон'}
                    error={
                      errors.phone && touched.phone ? errors.phone : undefined
                    }
                  >
                    <CabinetTextInputPhone
                      type='tel'
                      id='phone'
                      name='phone'
                      value={values.phone}
                      onAccept={(value: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('phone', value)
                      }
                      onChange={handleChange}
                      mask={process.env.NEXT_PUBLIC_PHONE_MASK}
                    />
                  </CabinetFormField>
                </FormCol>

                <FormCol>
                  <CabinetFormField
                    label={'Email (необязательно)'}
                    error={
                      errors.email && touched.email ? errors.email : undefined
                    }
                  >
                    <CabinetTextInput
                      id='email'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormRow>
                <FormCol>
                  <CabinetFormField label={'Комментарий'}>
                    <CabinetTextArea
                      id='comment'
                      name='comment'
                      value={values.comment}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormAction>
                <FormActionCol customWidth={40}>
                  <CabinetButton
                    variant={'contained'}
                    type='submit'
                    disabled={isSubmitting}
                  >
                    Отправить
                  </CabinetButton>
                </FormActionCol>
              </FormAction>
            </CabinetFormTemplate>
          </FormWrapper>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  )
}

export default CarValuationFormModal
