import React from 'react'
import styled from '@emotion/styled/macro'

import Spinner from '@/components/Spinner'

function CabinetLoader() {
  return (
    <Component>
      <Spinner absolute={true} isCabinet={true} />
    </Component>
  )
}

const Component = styled.div`
  position: relative;
  min-height: 60vh;
  width: 100%;
`

export default CabinetLoader
