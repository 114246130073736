import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import CardTemplate from '../components/CardTemplate'
import CardTop from '../components/CardTemplate/components/CardTop'
import CardPreview from '../components/CardTemplate/components/CardPreview'
import CardAction from '../components/CardTemplate/components/CardAction'
import CardDetails from '../components/CardTemplate/components/CardDetails'
import CardDetailsContentSpare from '../components/CardTemplate/components/CardDetails/components/CardDetailsContentSpare'

import { IUserRequestsType } from '@/services/users/typings'
import { media } from '@/utils/mixin'

interface Props {
  card: IUserRequestsType
}

function RequestsSpareCard({ card }: Props) {
  const { number, dealer, status } = card

  return (
    <CardTemplate>
      <CardTop isIcon={true}>
        <CardPreview boldText={number ?? ''} dealer={dealer} isIcon={true} />
        <CardAction>
          <CardText>{status}</CardText>
        </CardAction>
      </CardTop>
      <CardDetails
        defaultValue={{ works: [], parts: [], spare: [card] }}
        content={CardDetailsContentSpare}
      />
    </CardTemplate>
  )
}

const CardText = styled.div`
  line-height: 24px;
  font-size: 14px;
  color: ${props => props.theme.blue100};
  text-align: right;

  ${media.mobile(css`
    text-align: left;
  `)}
`

export default RequestsSpareCard
