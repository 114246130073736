import { ServiceChooseCarCenterFormFields } from './components/ServiceFormView/components/ServiceChooseCarCenterForm/types'
import { ServiceContactFormFields } from './components/ServiceFormView/components/ServiceContactForm/types'
import { ServiceChooseCarFormFields } from './components/ServiceFormView/components/ServiceChooseCarForm/types'

import { Nullable } from '@/typings/common'

export enum ServiceFormState {
  Contacts = 'Заполните контактные данные',
  Car = 'Выберите автомобиль',
  CarCenter = 'Выберите автоцентр',
}

export type ServiceFormViewProps =
  | {
      state: ServiceFormState.Contacts
      props: {
        onNextStep: (values: ServiceContactFormFields) => void
      }
    }
  | {
      state: ServiceFormState.Car
      props: {
        onPreviousStep: () => void
        onNextStep: (values: ServiceChooseCarFormFields) => void
      }
    }
  | {
      state: ServiceFormState.CarCenter
      props: {
        onNextStep: (values: ServiceChooseCarCenterFormFields) => Promise<void>
        onPreviousStep: () => void
        carData: Nullable<ServiceChooseCarFormFields>
      }
    }
