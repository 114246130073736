import React from 'react'
import styled from '@emotion/styled'

import { getTableHeaderList, getWorksAndPartsRow } from '../../helpers'

import { CardDetailsType } from '@/services/users/typings'
import CardTable from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardTable'

interface Props {
  cardDetails: CardDetailsType
}

function CardDetailsContentWorksAndParts({ cardDetails }: Props) {
  return (
    <Component>
      {cardDetails?.works?.length > 0 && (
        <CardTable
          headerColList={getTableHeaderList('PRICE_WITH_VAL')}
          dataTable={getWorksAndPartsRow(cardDetails.works)}
          title={'Услуги'}
        />
      )}
      {cardDetails?.parts?.length > 0 && (
        <CardTable
          headerColList={getTableHeaderList('PRICE_WITH_VAL')}
          dataTable={getWorksAndPartsRow(cardDetails.parts)}
          title={'Запчасти и жидкости'}
        />
      )}
    </Component>
  )
}

const Component = styled.div``

export default CardDetailsContentWorksAndParts
