import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import {
  RepairPartsRequestFormModalFields,
  RepairPartsRequestFormModalProps,
} from './types'
import {
  initialRepairPartsRequestFormModalValues,
  validationRepairPartsRequestFormModalSchema,
} from './helpers'

import {
  FormAction,
  FormActionCol,
  FormCol,
  FormNote,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetSelect from '@/components/PersonalCabinetForms/components/CabinetSelect'
import CabinetTextArea from '@/components/PersonalCabinetForms/components/CabinetTextArea/CabinetTextArea'
import { OptionType } from '@/typings/common'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import SuccessForm from '@/components/Forms/SuccessForm'
import {
  Component,
  Title,
  Vin,
  Brand,
  FormWrapper,
} from '@/components/PersonalCabinetForms/modals/common.styles'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'
import {
  getDealerId,
  getDealerOptions,
  getFullDealerOptions,
} from '@/modules/PersonalData/utils'

function RepairPartsRequestFormModal(props: RepairPartsRequestFormModalProps) {
  const { car, brands, onSubmit } = props
  const dealerOptions = car.brand
    ? getDealerOptions(car.brand, brands)
    : getFullDealerOptions(brands)

  const [activeDealerOption, setActiveDealerOption] = useState<OptionType>(
    dealerOptions[0],
  )
  const [isFormSent, setFormSent] = useState(false)

  useLockBodyScroll()

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik<RepairPartsRequestFormModalFields>({
    initialValues: initialRepairPartsRequestFormModalValues,
    validationSchema: validationRepairPartsRequestFormModalSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        onSubmit?.(values).then(() => setFormSent(true))
      } catch (error) {
        console.log(error)
      }

      setSubmitting(false)
    },
  })

  useEffect(() => {
    setFieldValue('dealer', getDealerId(brands, activeDealerOption.value))
  }, [activeDealerOption.value, brands, car.vin, setFieldValue])

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      {!isFormSent ? (
        <React.Fragment>
          <Title>Заявка на запчасти</Title>

          <Brand>
            {car.brandName} {car.modelName}
          </Brand>
          <Vin>{car.vin}</Vin>

          <FormWrapper>
            <CabinetFormTemplate>
              <FormRow>
                <FormCol>
                  <CabinetFormField
                    label={'Перечень запчастей'}
                    error={
                      errors.message && touched.message
                        ? errors.message
                        : undefined
                    }
                  >
                    <CabinetTextArea
                      id='message'
                      name='message'
                      value={values.message}
                      onChange={handleChange}
                    />
                  </CabinetFormField>
                  <FormNote>Введите запчасти, которые вам нужны</FormNote>
                </FormCol>

                <FormCol>
                  <CabinetFormField
                    label={'Выберите автоцентр'}
                    error={
                      errors.dealer && touched.dealer
                        ? errors.dealer
                        : undefined
                    }
                  >
                    <CabinetSelect
                      value={activeDealerOption}
                      options={dealerOptions}
                      onChange={newSelectedValue => {
                        setActiveDealerOption(newSelectedValue)
                        setFieldValue(
                          'dealer',
                          getDealerId(brands, newSelectedValue.value),
                        )
                      }}
                    />
                  </CabinetFormField>
                </FormCol>
              </FormRow>

              <FormAction>
                <FormActionCol customWidth={40}>
                  <CabinetButton
                    variant={'contained'}
                    type='submit'
                    disabled={isSubmitting}
                  >
                    Отправить
                  </CabinetButton>
                </FormActionCol>
              </FormAction>
            </CabinetFormTemplate>
          </FormWrapper>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  )
}

export default RepairPartsRequestFormModal
