import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { ServiceFormState, ServiceFormViewProps } from './types'
import ServiceFormView from './components/ServiceFormView'
import { ServiceChooseCarFormFields } from './components/ServiceFormView/components/ServiceChooseCarForm/types'
import { ServiceChooseCarCenterFormFields } from './components/ServiceFormView/components/ServiceChooseCarCenterForm/types'

import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'
import { Nullable } from '@/typings/common'
import { getBrandById, getCarById } from '@/modules/PersonalData/utils'
import { scrollToTop } from '@/utils/scroll'
import { stepsData } from '@/modules/Service/constants'
import { submitCabinetService } from '@/services/leads/leads-service'

function MainContent() {
  const {
    service,
    brands,
    setServiceActiveStepAction,
    setServiceContactsDataAction,
    setServiceChooseCarDataAction,
    setServiceChooseCarCenterDataAction,
    setServiceFormSubmitAction,
  } = useProfile()
  const activeFormStep = service.activeStep
  const cars = service.cars
  const contactsData = service.contactsData
  const carData = service.chooseCarData

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitServiceForm = async (
    car: Nullable<ServiceChooseCarFormFields>,
    carCenter: Nullable<ServiceChooseCarCenterFormFields>,
  ): Promise<void> => {
    try {
      if (car?.carId) {
        const cabinetCar = getCarById(car.carId, cars)

        await submitCabinetService({
          name: contactsData?.name ?? '',
          surname: contactsData?.surname ?? '',
          patronymic: contactsData?.patronymic ?? '',
          phone: contactsData?.phone ?? '',
          carBrand: cabinetCar?.brandName ?? '',
          carModel: cabinetCar?.modelName ?? '',
          carYear: cabinetCar?.year ?? 0,
          carVin: cabinetCar?.vin ?? '',
          carPlate: cabinetCar?.number ?? '',
          carMileage: cabinetCar?.mileage ?? 0,
          dealer: carCenter?.dealer ?? 0,
          datetime: carCenter?.date
            ? `${carCenter.date}${carCenter.time ? ' ' + carCenter.time : ''}`
            : '',
          email: contactsData?.email ?? '',
          to: carCenter?.to,
          recommended: carCenter?.recommended,
          comment: carCenter?.comment ?? '',
        })
      } else if (car?.carBrand) {
        await submitCabinetService({
          name: contactsData?.name ?? '',
          surname: contactsData?.surname ?? '',
          patronymic: contactsData?.patronymic ?? '',
          phone: contactsData?.phone ?? '',
          carBrand: getBrandById(car.carBrand, brands.data)?.name ?? '',
          carModel: car?.carModel ?? '',
          carYear: car?.carYear ?? 0,
          carVin: car?.carVin ?? '',
          carPlate: car?.carPlate ?? '',
          carMileage: car?.carMileage ? Number(car.carMileage) : 0,
          dealer: carCenter?.dealer ?? 0,
          datetime: carCenter?.date ?? '',
          email: contactsData?.email ?? '',
          comment: carCenter?.comment ?? '',
        })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // @ts-ignore
  const props = React.useMemo((): ServiceFormViewProps => {
    switch (activeFormStep) {
      case ServiceFormState.Contacts:
        return {
          state: activeFormStep,
          props: {
            onNextStep: values => {
              scrollToTop('auto')
              setServiceContactsDataAction(values)
              setServiceActiveStepAction(stepsData.steps[1])
            },
          },
        }

      case ServiceFormState.Car:
        return {
          state: activeFormStep,
          props: {
            onNextStep: values => {
              scrollToTop('auto')
              setServiceChooseCarDataAction(values)
              setServiceActiveStepAction(stepsData.steps[2])
            },
            onPreviousStep: () => {
              scrollToTop('auto')
              setServiceActiveStepAction(stepsData.steps[0])
            },
          },
        }

      case ServiceFormState.CarCenter:
        return {
          state: activeFormStep,
          props: {
            onNextStep: async values => {
              scrollToTop('auto')
              setServiceChooseCarCenterDataAction(values)

              try {
                await handleSubmitServiceForm(carData, values).then(() =>
                  setServiceFormSubmitAction(true),
                )
              } catch (error) {
                return Promise.reject(error)
              }
            },
            onPreviousStep: () => {
              scrollToTop('auto')
              setServiceActiveStepAction(stepsData.steps[1])
            },
            carData,
          },
        }
    }
  }, [
    activeFormStep,
    carData,
    handleSubmitServiceForm,
    setServiceActiveStepAction,
    setServiceChooseCarCenterDataAction,
    setServiceChooseCarDataAction,
    setServiceContactsDataAction,
    setServiceFormSubmitAction,
  ])

  return (
    <Component>
      <Title>Онлайн-запись на сервис</Title>
      <ServiceFormViewWrapper>
        <ServiceFormView {...props} />
      </ServiceFormViewWrapper>
    </Component>
  )
}

export default MainContent

const Component = styled.div``

const Title = styled.p`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  color: ${props => props.theme.main};

  ${media.tabletSmall(css`
    font-size: 24px;
  `)}
`

const ServiceFormViewWrapper = styled.div`
  margin-top: 30px;
`
