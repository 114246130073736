import { IncomingMessage } from 'http'

import Cookies from 'js-cookie'

import { OptionType, UtmType } from '@/typings/common'

type Nullable<T> = T | null

function getCookie(field: string): Nullable<string> {
  return Cookies.get(field) ?? null
}

function updateCookie(
  field: string,
  value: string,
  expiresInDays = 365,
  domain?: string,
): void {
  Cookies.set(field, value, {
    path: '/',
    expires: expiresInDays,
    domain,
  })
}

function removeCookie(field: string, domain?: string): void {
  return Cookies.remove(field, { domain })
}

const cookie = {
  get: getCookie,
  set: updateCookie,
  remove: removeCookie,
} as const

export default cookie

export const getCityFromCookie = (): Nullable<OptionType> => {
  const cityFromCookie = cookie.get('selectedCity')

  return cityFromCookie ? JSON.parse(cityFromCookie) : null
}

export const getUtmFromCookie = (req?: IncomingMessage): Nullable<UtmType> => {
  const utmFromCookie = cookie.get('utm')

  return utmFromCookie ? JSON.parse(utmFromCookie) : null
}
