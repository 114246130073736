import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { MOCK_TIME_OUTSIDE_TEST_DRIVE } from './TimePicker.helpers'

import {
  CarsType,
  ITestDriveFormUserValuesData,
  Nullable,
} from '@/typings/common'

interface Props {
  value: string
  times: Nullable<Array<string>>
  onClick: (value: string) => void
  onClose: () => void
  values?: ITestDriveFormUserValuesData
  typeState?: CarsType
  className?: string
}

function TimePicker({
  value,
  onClick,
  onClose,
  values,
  times,
  typeState,
  className,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState(0)

  useLayoutEffect(() => {
    const timeElement = ref.current

    if (timeElement) {
      const coordinate = timeElement.getBoundingClientRect()
      const currentHeight = coordinate.height

      setPosition(-currentHeight)
    }
  }, [])

  useEffect(() => {
    function handleClickByOutSide(event: MouseEvent) {
      if (ref.current && event.target instanceof Element) {
        if (event.target.contains(ref.current)) {
          onClose()
        }
      }
    }

    document.addEventListener('click', handleClickByOutSide)
    return () => document.removeEventListener('click', handleClickByOutSide)
  }, [onClose])

  function handleClick(value: string) {
    onClick(value)
  }

  return (
    <Component className={className} ref={ref} position={position}>
      <TimeList>
        {!values?.outsideTestDrive && !times && typeState !== 'amp' && (
          <Span>{'На выбранную дату нету свободного времени'}</Span>
        )}
        {times &&
          times.map((timeItem, index) => (
            <TimeItem
              key={index}
              isActive={value === timeItem}
              onClick={() => handleClick(timeItem)}
            >
              {timeItem}
            </TimeItem>
          ))}

        {values?.outsideTestDrive &&
          MOCK_TIME_OUTSIDE_TEST_DRIVE.map((timeItem, index) => (
            <TimeItem
              key={index}
              isActive={values.time === timeItem}
              onClick={() => handleClick(timeItem)}
            >
              {timeItem}
            </TimeItem>
          ))}
      </TimeList>
    </Component>
  )
}

const Component = styled.div<{ position: number }>`
  width: 100%;
  height: auto !important;
  position: absolute;

  ${props =>
    !!props.position
      ? css`
          top: ${props.position}px;
        `
      : null}

  left: 0;
  right: -0;
  padding: 12px;
  background: ${props => props.theme.white_1};
  border: 1px solid ${props => props.theme.gray1800};
  box-shadow: ${props => props.theme.blackShadow100};
  border-radius: 5px;
  z-index: 2000;
`

const TimeItem = styled.span<{ isActive: boolean }>`
  display: block;
  font-size: 13px;
  margin: 3px;
  line-height: 24px;
  padding: 2px 10px;
  border: 1px solid ${props => props.theme.black100_alpha15};
  border-radius: 4px;
  cursor: pointer;
  color: ${props => props.theme.black};

  ${props =>
    props.isActive
      ? css`
          background: ${props.theme.main};
          color: ${props.theme.white};
          border-color: ${props.theme.main};
        `
      : null};
`

const TimeList = styled.div`
  display: flex;

  flex-wrap: wrap;
`

const Span = styled.span`
  font-size: 12px;
  color: ${props => props.theme.blue100};
`

export default TimePicker
