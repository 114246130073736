import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import CabinetHeader from '@/modules/Cabinet/components/CabinetHeader'
import { ContentContainerSmall } from '@/components/common'
import CabinetLoader from '@/modules/Cabinet/components/CabinetLoader'
import { useProfile } from '@/providers/Profile'

export type CabinetHeaderTabs =
  | 'HOME'
  | 'CARS'
  | 'BONUSES'
  | 'PURCHASES'
  | 'REQUESTS'
  | 'SERVICE'

interface Props {
  activeTab: CabinetHeaderTabs
  children?: React.ReactNode
  canActiveTabHover?: boolean
  backgroundWhite?: boolean
}

function Cabinet({
  activeTab,
  children,
  canActiveTabHover,
  backgroundWhite,
}: Props) {
  const { pageLoading } = useProfile()
  return (
    <Template>
      <CabinetHeader
        activeTab={activeTab}
        canActiveTabHover={canActiveTabHover}
      />
      <CabinetBody backgroundWhite={backgroundWhite}>
        <ContentContainerSmall>
          {pageLoading ? (
            <CabinetLoader />
          ) : (
            <CabinetBodyInner>{children}</CabinetBodyInner>
          )}
        </ContentContainerSmall>
      </CabinetBody>
    </Template>
  )
}

export default Cabinet

const Template = styled.div``

const CabinetBody = styled.div<{ backgroundWhite?: boolean }>`
  background: ${({ backgroundWhite, theme }) =>
    backgroundWhite ? `${theme.white}` : `${theme.gray200}`};
  padding: 30px 0;
  position: relative;
`

const CabinetBodyInner = styled.div`
  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`
