import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import Header from '@/modules/Login/components/Header'
import { ContentContainerSmall } from '@/components/common'
import LoginWindow from '@/modules/Login/components/LoginWindow'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

function Login() {
  useDocumentTitle('Личный кабинет Атлант-М')
  return (
    <Component>
      <Header />
      <Content>
        <Container>
          <LoginWindow />
        </Container>
      </Content>
    </Component>
  )
}

export default Login

const Component = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.gray200};
`

const Content = styled.div`
  flex: 1;
  padding: 90px 0;
  background-color: ${props => props.theme.white};

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`

const Container = styled(ContentContainerSmall)`
  height: 100% !important;
  display: flex;
  align-items: center;
`
