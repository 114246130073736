import React, { useState } from 'react'

import {
  Field,
  FieldInner,
  Plus,
  Text,
} from '@/modules/PersonalData/components/InformationBlock/components/AddEmail/common.styles'
import AddPhoneModal from '@/components/modals/AddPhoneModal'

function AddPhone() {
  const [isOpen, setIsOpen] = useState(false)
  console.log()
  return (
    <Field>
      <FieldInner onClick={() => setIsOpen(true)}>
        <Plus />
        <Text>Добавить Телефон</Text>
      </FieldInner>
      <AddPhoneModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    </Field>
  )
}

export default AddPhone
