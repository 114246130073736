import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

interface Props {
  title: string
  headerColList: string[]
}

function HeaderRow({ title, headerColList }: Props) {
  return (
    <Component>
      <Left>
        <Title>{title}</Title>
      </Left>
      <Right countCol={headerColList.length}>
        {headerColList?.map((col, index) => <Col key={index}>{col}</Col>)}
      </Right>
    </Component>
  )
}

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Left = styled.div``

const Right = styled.div<{ countCol: number }>`
  flex: 1 1 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.tabletSmall(css`
    display: none;
  `)}

  ${({ countCol }) =>
    countCol < 5 &&
    css`
      ${Col} {
        width: 110px;
      }
    `}
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.black100};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`

const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;

  width: 80px;
  margin-left: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.gray500};

  &:last-child {
    flex: 0 0 110px;
    min-width: 110px;
    width: 100%;
    margin-left: 20px;
  }
`

export default HeaderRow
