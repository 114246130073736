import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { ReservedButtonVariant, ReservedButtonSizeVariant } from './types'

import { CssSnippet } from '@/utils/mixin'

export const getVariantCssMap = (
  theme: Theme,
): Record<ReservedButtonVariant, CssSnippet> => {
  return {
    contained: css`
      border-radius: 20px;
      background: #0061ed;
      color: #fff;

      &:hover {
        background: #0556cc;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    `,
    outlined: css`
      border: 1px solid ${theme.main};
      border-radius: 20px;
      color: ${theme.main};

      &:hover {
        border: 1px solid #0556cc;
        background: #0556cc;
        color: #fff;
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    `,
    outlined100: css``,
  }
}

const defaultSizeCss = css`
  height: 44px;
  padding: 14px 15px;
  font-size: 14px;
  line-height: 16px;
`

const getSizeCssMap = (): Record<ReservedButtonSizeVariant, CssSnippet> => {
  return {
    small: css`
      height: 37px;
      padding: 11px 15px;
      font-size: 12px;
      line-height: 14px;
    `,
  }
}

const defaultButtonStyles = ({
  theme,
  variant,
  size,
}: {
  theme: Theme
  variant?: ReservedButtonVariant
  size?: ReservedButtonSizeVariant
}) => css`
  position: relative;
  width: 100%;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;

  ${typeof variant === 'string' ? getVariantCssMap(theme)[variant] : variant}

  ${typeof size === 'string' ? getSizeCssMap()[size] : defaultSizeCss}
`

export const StyledButton = styled.button<{
  variant?: ReservedButtonVariant
  size?: ReservedButtonSizeVariant
}>`
  ${defaultButtonStyles}
`

export const StyledLink = styled.a<{
  variant?: ReservedButtonVariant
}>`
  ${defaultButtonStyles}
`
