import React from 'react'
import { Form, FormikProps } from 'formik'
// @ts-ignore
import { IMaskInput } from 'react-imask'
import styled from '@emotion/styled'

import { ReactComponent as ArrowIcon } from '@/assets/svg/login/arrow-right.svg'
import Spinner from '@/components/Spinner'
import CabinetTextInputFormik from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.formik'
import { AddEmailPayload } from '@/services/users/typings'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'

export type AddEmailFormValues = AddEmailPayload

type Props = FormikProps<AddEmailPayload> & {
  isLoading: boolean
  isEmailSend: string | null
}

function AddEmailForm({
  isLoading,
  isEmailSend,
  handleChange,
  values,
  errors,
}: Props) {
  return (
    <Form autoComplete='off' noValidate>
      <TextFieldWrapper>
        {isEmailSend ? (
          <CabinetFormField
            error={errors.code ?? ''}
            label={'Код подтверждения'}
          >
            <Input
              type='text'
              id='code'
              name='code'
              mask='0000'
              value={values.code}
              placeholder='Код из Email-сообщения'
              onChange={handleChange}
            />
          </CabinetFormField>
        ) : (
          <CabinetFormField error={errors.email ?? ''} label={'Email'}>
            <CabinetTextInputFormik
              id='email value'
              type='email'
              name='email'
            />
          </CabinetFormField>
        )}
        <SubmitButton disabled={isLoading} type={'submit'}>
          {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
        </SubmitButton>
      </TextFieldWrapper>
    </Form>
  )
}

export default AddEmailForm

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 320px;
`

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.main};
  transition: 0.3s;

  &:hover {
    background: ${props => props.theme.main100};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background: ${props => props.theme.main};
    }
  }
`

const Input = styled(IMaskInput)`
  ${InputStyles}
`
