import { OptionType } from '@/typings/common';

export const TABS_LIST: OptionType[] = [
  {
    label: 'Сервис',
    value: 'service',
  },
  {
    label: 'Страховки',
    value: 'insurance',
  },
  {
    label: 'Автомобили',
    value: 'cars',
  },
  {
    label: 'Прочие',
    value: 'other',
  },
];

export const OPTIONS_LIST: OptionType[] = [
  {
    label: 'Сначала новые',
    value: 'new',
  },
  {
    label: 'Сначала старые',
    value: 'old',
  },
];
