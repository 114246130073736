import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Row from './components/Row'
import RowMobile from './components/RowMobile'
import HeaderRow from './components/HeaderRow'

import { media } from '@/utils/mixin'
import { StringField } from '@/typings/common'
import useMedia from '@/hooks/useMedia'

interface Props {
  headerColList: string[]
  dataTable: Array<{ row: string[]; name: StringField }>
  title?: string
}

function CardTable({ headerColList, dataTable, title }: Props) {
  const isTabletSmall = useMedia('(max-width: 1023px)')

  return (
    <Component>
      {title && (
        <Header>
          <HeaderRow title={title} headerColList={headerColList} />
        </Header>
      )}
      <Body>
        {dataTable?.map((rowData, index) =>
          isTabletSmall ? (
            <RowMobile
              key={index}
              rowData={rowData}
              headerColList={headerColList}
            />
          ) : (
            <Row key={index} rowData={rowData} />
          ),
        )}
      </Body>
    </Component>
  )
}

const Component = styled.div`
  margin-bottom: 40px;

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }
`

const Header = styled.div`
  margin-bottom: 20px;

  ${media.tabletSmall(css`
    margin-bottom: 15px;
  `)}
`

const Body = styled.div``

export default CardTable
