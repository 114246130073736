import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StringField } from '@/typings/common'

interface Props {
  rowData: { row: string[]; name: StringField }
}

function Row({ rowData }: Props) {
  return (
    <Component>
      <Left>{rowData.row.length !== 2 && <Text>{rowData.name}</Text>}</Left>
      <Right countCol={rowData.row.length}>
        {rowData.row.map((col, index) => (
          <Col key={index} dangerouslySetInnerHTML={{ __html: col ?? '' }} />
        ))}
      </Right>
    </Component>
  )
}

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`

const Left = styled.div``

const Right = styled.div<{ countCol: number }>`
  flex: 1 1 1px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ countCol }) =>
    countCol < 5 &&
    css`
      ${Col} {
        width: 110px;
      }
    `}
`

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.black100};
`

const Col = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 80px;
  margin-left: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: end;
  color: ${props => props.theme.blue100};

  &:last-child {
    flex: 0 0 110px;
    color: ${props => props.theme.black100};
    font-size: 14px;
    margin-left: 20px;
    min-width: 110px;
    width: 100%;
    text-align: right;
    text-transform: uppercase;
  }

  span {
    font-size: 18px;
    line-height: 24px;
    margin-right: 3px;
  }
`

export default Row
