import { cloneDeep } from 'lodash'

import { Nullable, OptionType } from '@/typings/common'
import { transformDate } from '@/utils/date'
import { UserProfilePreviewAvailability } from '@/services/users/typings'

export function getVisibleTabs(
  tabs: OptionType[],
  availability: Nullable<UserProfilePreviewAvailability>,
) {
  if (!availability) {
    return tabs
  }

  const {
    purchasesInsurances,
    purchasesCars,
    purchasesOther,
    purchasesServices,
  } = availability

  let visibleTabs = cloneDeep(tabs)

  if (!purchasesServices) {
    visibleTabs = visibleTabs.filter(tab => tab.value !== 'service')
  }

  if (!purchasesInsurances) {
    visibleTabs = visibleTabs.filter(tab => tab.value !== 'insurance')
  }

  if (!purchasesCars) {
    visibleTabs = visibleTabs.filter(tab => tab.value !== 'cars')
  }

  if (!purchasesOther) {
    visibleTabs = visibleTabs.filter(tab => tab.value !== 'other')
  }

  return visibleTabs
}

export function handlerServiceCheckbox(list: any) {
  return list?.filter((card: any) => card.isTo)
}

export function handlerServiceSearch(searchQuery: string, list: any) {
  return list?.filter((card: any) => {
    const carDocument = card.document?.toLowerCase()
    const carTotal = String(card.total)
    const carData = transformDate(card.date)
    const searchValue = searchQuery.toLowerCase()

    return (
      carDocument?.includes(searchValue) ||
      carTotal?.includes(searchValue) ||
      carData?.includes(searchValue)
    )
  })
}

export function handlerInsuranceSearch(searchQuery: string, list: any) {
  return list?.filter((card: any) => {
    const number = card.number?.toLowerCase()
    const price = String(card.price)
    const data = transformDate(card.date)
    const searchValue = searchQuery.toLowerCase()

    return (
      number?.includes(searchValue) ||
      price?.includes(searchValue) ||
      data?.includes(searchValue)
    )
  })
}

export function handlerCarsSearch(searchQuery: string, list: any) {
  return list?.filter((card: any) => {
    const number = card.number?.toLowerCase()
    const price = String(card.price)
    const data = transformDate(card.date)
    const searchValue = searchQuery.toLowerCase()

    return (
      number?.includes(searchValue) ||
      price?.includes(searchValue) ||
      data?.includes(searchValue)
    )
  })
}

export function handlerOtherSearch(searchQuery: string, list: any) {
  return list?.filter((card: any) => {
    const document = card.document?.toLowerCase()
    const price = String(card.total)
    const data = transformDate(card.date)
    const searchValue = searchQuery.toLowerCase()

    return (
      document?.includes(searchValue) ||
      price?.includes(searchValue) ||
      data?.includes(searchValue)
    )
  })
}
