import React from 'react'

import CardTemplate from '../components/CardTemplate'
import CardTop from '../components/CardTemplate/components/CardTop'
import CardPreview from '../components/CardTemplate/components/CardPreview'
import CardBill from '../components/CardTemplate/components/CardBill'
import CardDate from '../components/CardTemplate/components/CardDate'
import CardDetails from '../components/CardTemplate/components/CardDetails'
import CardDetailsContentWorksAndParts from '../components/CardTemplate/components/CardDetails/components/CardDetailsContentWorksAndParts'

import { getUserPurchasesOthersDetails } from '@/services/users/users-service'
import { PurchasesOthersItemType } from '@/services/users/typings'

interface Props {
  card: PurchasesOthersItemType
}

function PurchasesOtherCard({ card }: Props) {
  const { date, document, dealer, total, discount, manager, currency } = card

  return (
    <CardTemplate>
      <CardTop isIcon={true}>
        <CardDate date={date} />
        <CardPreview
          boldText={'№' + document}
          dealer={dealer.name}
          isIcon={true}
        >
          {manager && <div>Менеджер · {manager}</div>}
        </CardPreview>
        <CardBill total={total} currency={currency} discount={discount} />
      </CardTop>
      <CardDetails
        dataFetch={() =>
          getUserPurchasesOthersDetails(document ?? '', Number(dealer.id))
        }
        content={CardDetailsContentWorksAndParts}
      />
    </CardTemplate>
  )
}

export default PurchasesOtherCard
