import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { StringField } from '@/typings/common'

interface Props {
  total: Number
  currency: StringField
  discount?: Number
}

function CardBill({ total, currency, discount }: Props) {
  return (
    <Component>
      <Price>
        <span>{total + ''}</span> {currency}
      </Price>
      {discount ? <Discount>скидка · {discount.toFixed(2)}</Discount> : null}
    </Component>
  )
}

const Component = styled.div`
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobile(css`
    margin-top: 10px;
    flex-direction: row;
    align-items: flex-end;
  `)}
`

const Price = styled.div`
  color: ${props => props.theme.black100};
  font-size: 14px;

  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
  }

  ${media.mobile(css`
    display: flex;
    align-items: flex-end;
    margin-right: 20px;

    span {
      margin-right: 4px;
    }
  `)}
`

const Discount = styled.div`
  color: ${props => props.theme.gray500};
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  width: 100%;
  text-align: right;

  ${media.mobile(css`
    text-align: left;
    margin-top: 0;
    line-height: 24px;
  `)}
`

export default CardBill
