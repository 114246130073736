import * as Yup from 'yup'

import { ServiceChooseCarCenterFormFields } from './types'

export const initialServiceChooseCarCenterFormValues: ServiceChooseCarCenterFormFields =
  {
    dealer: 0,
    to: '',
    recommended: false,
    date: '',
    time: '',
    comment: '',
  }

export const validationServiceChooseCarCenterFormSchema: Yup.Schema<ServiceChooseCarCenterFormFields> =
  Yup.object().shape({
    dealer: Yup.number()
      .required('Выберите автоцентр')
      .min(1, 'Выберите автоцентр'),
    to: Yup.lazy(value => {
      switch (typeof value) {
        case 'undefined':
          return Yup.string()
        case 'string':
          return Yup.string().strict()
        case 'number':
          return Yup.number().strict()
        default:
          throw new Yup.ValidationError('Value must be a string or `undefined`')
      }
    }),

    recommended: Yup.boolean(),
    date: Yup.string().required('Выберите дату'),
    time: Yup.string(),
    comment: Yup.string(),
  })
