import React from 'react'

import { ModalWindowProps } from '@/components/modals/ModalWindow/types'
import { RepairPartsRequestFormModalProps } from '@/components/PersonalCabinetForms/modals/RepairPartsRequestFormModal/types'
import ModalWindow from '@/components/modals/ModalWindow'
import RepairPartsRequestFormModal from '@/components/PersonalCabinetForms/modals/RepairPartsRequestFormModal'

export interface RepairPartsRequestModalProps
  extends Omit<ModalWindowProps, 'children' | 'maxWidth'>,
    RepairPartsRequestFormModalProps {}

function RepairPartsRequestModal(props: RepairPartsRequestModalProps) {
  return (
    <ModalWindow {...props} onRequestClose={props.closeModal} maxWidth={790}>
      <RepairPartsRequestFormModal {...props} />
    </ModalWindow>
  )
}

export default RepairPartsRequestModal
