import { OptionType } from '@/typings/common'

export const workTypes: OptionType<boolean>[] = [
  { label: 'Обязательные и рекомендуемые', value: true },
  { label: 'Обязательные', value: false },
]

export const dealersWithoutBooking: number[] = [105, 806, 906, 123]

export const isOnlineServiceDisabled = true
