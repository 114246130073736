import { useNavigate } from 'react-router-dom'

import { Nullable } from '@/typings/common'
import { CarsItemType, UserProfileModel } from '@/services/users/typings'
import { useProfile } from '@/providers/Profile'
import { initialServiceChooseCarFormValues } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/ServiceChooseCarForm.helpers'
import { initialServiceChooseCarCenterFormValues } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarCenterForm/ServiceChooseCarCenterForm.helpers'

export const useRedirectServicePage = () => {
  const {
    setServiceContactsDataAction,
    setServiceChooseCarDataAction,
    setServiceChooseCarCenterDataAction,
  } = useProfile()

  let navigate = useNavigate()
  return (
    car: Nullable<CarsItemType>,
    personalData: Nullable<UserProfileModel>,
    toId?: number | string,
    dealerId?: number,
    recommendationText?: string,
  ) => {
    setServiceContactsDataAction({
      name: personalData?.name ?? '',
      email: personalData?.emails[0] ?? '',
      patronymic: personalData?.patronymic ?? '',
      phone: personalData?.phones[0] ?? '',
      surname: personalData?.lastName ?? '',
    })
    setServiceChooseCarDataAction({
      ...initialServiceChooseCarFormValues,
      carId: car?.id,
      carBrand: car?.brand,
    })

    if (toId && dealerId) {
      setServiceChooseCarCenterDataAction({
        ...initialServiceChooseCarCenterFormValues,
        to: toId,
        dealer: dealerId,
      })
    }

    if (recommendationText) {
      setServiceChooseCarCenterDataAction({
        ...initialServiceChooseCarCenterFormValues,
        comment: recommendationText,
      })
    }

    navigate('/service')
  }
}
