import React from 'react'

import CardTemplate from '../components/CardTemplate'
import CardTop from '../components/CardTemplate/components/CardTop'
import CardDate from '../components/CardTemplate/components/CardDate'
import CardPreview from '../components/CardTemplate/components/CardPreview'
import CardBill from '../components/CardTemplate/components/CardBill'
import CardDetails from '../components/CardTemplate/components/CardDetails'
import CardDetailsContentWorksAndParts from '../components/CardTemplate/components/CardDetails/components/CardDetailsContentWorksAndParts'

import { getUserPurchasesServicesDetails } from '@/services/users/users-service'
import { PurchasesServiceItemType } from '@/services/users/typings'

interface Props {
  card: PurchasesServiceItemType
}

function PurchasesServiceCard({ card }: Props) {
  const {
    vin,
    document,
    date,
    dealer,
    total,
    currency,
    discount,
    master,
    mileage,
  } = card

  return (
    <CardTemplate>
      <CardTop isIcon={true}>
        <CardDate date={date} />
        <CardPreview
          boldText={'Заказ-наряд №' + document}
          dealer={dealer.name}
          isIcon={true}
        >
          {mileage && <div>Пробег · {mileage + ''} км</div>}
          {master && <div>Мастер · {master}</div>}
        </CardPreview>
        <CardBill total={total} currency={currency} discount={discount} />
      </CardTop>
      <CardDetails
        dataFetch={() =>
          getUserPurchasesServicesDetails(dealer.id, vin ?? '', document ?? '')
        }
        content={CardDetailsContentWorksAndParts}
      />
    </CardTemplate>
  )
}

export default PurchasesServiceCard
