import { StringField } from '@/typings/common'

export function getCurrentDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  )
}

export function getTomorrowDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1,
  )
}

export function getDayAfterTomorrowDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 2,
  )
}

export function transformDate(date: StringField) {
  if (!date) {
    return null
  }

  return date.split('-').reverse().join('.')
}
