import React from 'react'

import ModalWindow from '@/components/modals/ModalWindow'
import { ModalWindowProps } from '@/components/modals/ModalWindow/types'
import AddPhoneFormContainer from '@/components/PersonalCabinetForms/AddPhoneForm'

export interface AddPhoneModalModalProps
  extends Omit<ModalWindowProps, 'children' | 'maxWidth'> {
  closeModal: () => void
}

function AddPhoneModal(props: AddPhoneModalModalProps) {
  return (
    <ModalWindow {...props} onRequestClose={props.closeModal} maxWidth={360}>
      <AddPhoneFormContainer closeModal={props.closeModal} />
    </ModalWindow>
  )
}

export default AddPhoneModal
