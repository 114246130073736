import * as Yup from 'yup'

import { CarValuationFormModalFields } from './types'

import { phoneRegexp, emailRegexp } from '@/utils/textInput'

export const initialCarValuationFormModalValues: CarValuationFormModalFields = {
  vin: '',
  mileage: null,
  engineCapacity: '',
  fuel: null,
  gearbox: null,
  dealer: 0,

  name: '',
  surname: '',
  patronymic: '',
  phone: '',
  email: '',
  comment: '',
}

export const validationCarValuationFormModalSchema: Yup.Schema<CarValuationFormModalFields> =
  Yup.object().shape({
    vin: Yup.string(),
    mileage: Yup.number().nullable(),
    engineCapacity: Yup.string(),
    fuel: Yup.number().nullable(),
    gearbox: Yup.number().nullable(),
    dealer: Yup.number()
      .required('Выберите автоцентр')
      .min(1, 'Выберите автоцентр'),
    name: Yup.string().required('Введите имя'),
    surname: Yup.string().required('Введите фамилию'),
    patronymic: Yup.string().required('Введите отчество'),
    phone: Yup.string()
      .required('Укажите номер телефона')
      .matches(phoneRegexp, 'Телефон введен неверно'),
    email: Yup.string().matches(emailRegexp, 'Email введен неверно'),
    comment: Yup.string(),
  })
