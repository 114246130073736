import React from 'react'

import { ServiceFormState, ServiceFormViewProps } from '../../types'

import ServiceChooseCarCenterForm from './components/ServiceChooseCarCenterForm'
import ServiceChooseCarForm from './components/ServiceChooseCarForm'
import ServiceContactForm from './components/ServiceContactForm'

const getContent = (params: ServiceFormViewProps) => {
  switch (params.state) {
    case ServiceFormState.Contacts:
      return <ServiceContactForm {...params.props} />

    case ServiceFormState.Car:
      return <ServiceChooseCarForm {...params.props} />

    case ServiceFormState.CarCenter:
      return <ServiceChooseCarCenterForm {...params.props} />

    default:
      return <div>Default</div>
  }
}

function ServiceFormView(props: ServiceFormViewProps) {
  return getContent(props)
}

export default ServiceFormView
