import { useCallback, useLayoutEffect } from 'react'

export interface UseLockBodyScrollOptions {
  lockIfOpened?: boolean
}

export const useLockBodyScroll = (options?: UseLockBodyScrollOptions) => {
  const handleUpdateBodyOverflow = useCallback(
    (overflow: string) => {
      if (options) {
        if (options.lockIfOpened) {
          document.body.style.overflow = overflow
        }
      } else {
        document.body.style.overflow = overflow
      }
    },
    [options],
  )

  useLayoutEffect(() => {
    const isBodyScrollLocked = document.body.style.overflow === 'hidden'

    if (isBodyScrollLocked) {
      return
    }

    handleUpdateBodyOverflow('hidden')

    return () => {
      handleUpdateBodyOverflow('auto')
    }
  }, [handleUpdateBodyOverflow])
}
