import { FormikErrors, FormikHelpers } from 'formik'

import { ServiceChooseCarCenterFormFields } from './types'

import {
  CabinetBrandsPayload,
  ServiceTOPayload,
  ServiceTOPayloadDetails,
} from '@/services/users/typings'
import { Nullable, OptionType } from '@/typings/common'
import { DefaultOption } from '@/modules/PersonalData/utils'
import { ServiceChooseCarFormFields } from '@/modules/Service/components/MainContent/components/ServiceFormView/components/ServiceChooseCarForm/types'

export const getDealerOptionByDealerId = (
  brands: CabinetBrandsPayload[],
  dealerId: number,
): OptionType => {
  if (dealerId) {
    if (brands && brands.length !== 0) {
      const foundDealers = brands
        .find(brand => brand.dealers.find(dealer => dealer.id === dealerId))
        ?.dealers.filter(dealer => dealer.id === dealerId)

      if (!foundDealers || foundDealers.length < 1) {
        return DefaultOption
      }

      return { value: foundDealers[0].name, label: foundDealers[0].name }
    } else {
      return DefaultOption
    }
  } else {
    return DefaultOption
  }
}

export const getServiceTOOptions = (
  toData: Nullable<ServiceTOPayload[]>,
): OptionType[] => {
  if (!toData) {
    return [DefaultOption]
  }

  const helpOption: OptionType = {
    value: 'Другие работы',
    label: 'Другие работы',
  }

  const toOptions: OptionType[] = toData.map(({ name }) => {
    return { value: name, label: name }
  })

  return [helpOption, ...toOptions]
}

export const getServiceTOIdByName = (
  toData: ServiceTOPayload[],
  searchTo: string,
): number | string => {
  if (!toData || searchTo === 'Другие работы') {
    return ''
  }

  const foundTO = toData.find(({ name }) => name === searchTo)

  return foundTO?.id ?? ''
}

export const getServiceTOOptionById = (
  toData: ServiceTOPayload[],
  toId: number | string,
): OptionType => {
  if (!toData) {
    return DefaultOption
  }

  const toOption = toData.find(({ id }) => id === toId)

  if (!toOption) {
    return DefaultOption
  }

  return { value: String(toOption.id), label: toOption.name }
}

export const hasCustomFormErrors = (
  values: ServiceChooseCarCenterFormFields,
  carData: Nullable<ServiceChooseCarFormFields>,
  serviceTime: Nullable<string[]>,
  setErrors: (errors: FormikErrors<ServiceChooseCarCenterFormFields>) => void,
): boolean => {
  if (carData?.carId && !values.time && serviceTime && serviceTime.length > 0) {
    setErrors({ time: 'Выберите время' })
    return true
  }

  return false
}

export const handleSetCommentIfNotBooking = (
  setFieldValue: FormikHelpers<ServiceChooseCarCenterFormFields>['setFieldValue'],
  activeServiceTOOption: Nullable<OptionType>,
  serviceTODetailsData: Nullable<ServiceTOPayloadDetails>,
  carCenterData: Nullable<ServiceChooseCarCenterFormFields>,
  carData: Nullable<ServiceChooseCarFormFields>,
  timeslots: string[],
  isRequiredWorks: boolean,
  countGetTimeForTo: number,
) => {
  if (timeslots.length < 1 && carData?.carId && countGetTimeForTo === 3) {
    setFieldValue(
      'comment',
      `${
        activeServiceTOOption
          ? `Требуемые работы: ${activeServiceTOOption?.label}.`
          : ''
      } ${
        serviceTODetailsData?.summary.summ.total
          ? `Тип работ: ${
              isRequiredWorks ? 'обязательные' : 'обязательные и рекомендуемые'
            }.`
          : ''
      } ${
        serviceTODetailsData?.summary.summ.total
          ? `Стоимость работ: ${
              isRequiredWorks && serviceTODetailsData?.summary.summ.required
                ? serviceTODetailsData?.summary.summ.required
                : serviceTODetailsData?.summary.summ.total
            }.`
          : ''
      } ${carCenterData?.comment ? ` ${carCenterData.comment}` : ''}`,
    )
  } else {
    setFieldValue(
      'comment',
      `${carCenterData?.comment ? `${carCenterData.comment}` : ''}`,
    )
  }
}
