import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import BonusCard from '@/modules/PersonalData/components/PersonalBlock/components/BonusCard'

function BonusesHero() {
  const bonusesText = `<p>Программа лояльности «Атлант-М» позволяет Вам накапливать бонусы при каждом обращении в любой автоцентр Атлант-М, чтобы потом по своему усмотрению превращать их в скидки на товары и услуги в наших автоцентрах.<br><br>У нас Вы можете накапливать бонусы с каждой покупки, а потом самостоятельно выбрать, на что и в каком количестве их потратить.</p>`

  return (
    <Hero>
      <Left>
        <BonusCard isBonusPage={true} />
      </Left>
      <Right dangerouslySetInnerHTML={{ __html: bonusesText ?? '' }} />
    </Hero>
  )
}

const Hero = styled.div`
  display: flex;
  padding: 20px 30px 40px;
  background-color: ${props => props.theme.white};

  ${media.mobile(css`
    display: block;
    padding-bottom: 20px;
  `)}
`

const Left = styled.div`
  flex: 0 0 317px;
  margin-right: 60px;

  ${media.mobile(css`
    margin: 0 0 20px;
  `)}
`

const Right = styled.div`
  flex: 1 1 auto;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.gray500};
  }
`

export default BonusesHero
