import React, { useEffect } from 'react'
// @ts-ignore
import { IMaskInput } from 'react-imask'
import { useFormik } from 'formik'
import styled from '@emotion/styled/macro'

import {
  initialServiceContactFormValues,
  validationServiceContactFormSchema,
} from './ServiceContactForm.helpers'
import { ServiceContactFormFields, ServiceContactFormProps } from './types'

import { useProfile } from '@/providers/Profile'
import CabinetFormTemplate from '@/components/PersonalCabinetForms/components/CabinetFormTemplate'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import CabinetTextInput from '@/components/PersonalCabinetForms/components/CabinetTextInput'
import CabinetButton from '@/components/PersonalCabinetForms/components/CabinetButton'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'

function ServiceContactForm({ onNextStep }: ServiceContactFormProps) {
  const { profile, service } = useProfile()
  const contactsData = service.contactsData

  const {
    values,
    errors,
    setValues,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik<ServiceContactFormFields>({
    initialValues: initialServiceContactFormValues,
    validationSchema: validationServiceContactFormSchema,
    onSubmit: values => {
      onNextStep(values)
    },
  })

  useEffect(() => {
    if (contactsData) {
      setValues(contactsData)
    } else {
      setValues({
        surname: profile.data?.lastName ?? '',
        name: profile.data?.name ?? '',
        patronymic: profile.data?.patronymic ?? '',
        email: profile.data?.emails[0] ?? '',
        phone: profile.data?.phones[0] ?? '',
      })
    }
  }, [contactsData, profile.data, setValues])

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete='off'>
      <CabinetFormTemplate title={'Кто приедет?'}>
        <FormRow>
          <FormCol>
            <CabinetFormField
              label={'Фамилия'}
              error={
                errors.surname && touched.surname ? errors.surname : undefined
              }
            >
              <CabinetTextInput
                id='surname'
                type='text'
                name='surname'
                value={values.surname}
                onChange={handleChange}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <CabinetFormField
              label={'Имя'}
              error={errors.name && touched.name ? errors.name : undefined}
            >
              <CabinetTextInput
                id='name'
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
              />
            </CabinetFormField>
          </FormCol>

          <FormCol>
            <CabinetFormField
              label={'Отчество'}
              error={
                errors.patronymic && touched.patronymic
                  ? errors.patronymic
                  : undefined
              }
            >
              <CabinetTextInput
                id='patronymic'
                type='text'
                name='patronymic'
                value={values.patronymic}
                onChange={handleChange}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <CabinetFormField
              label={'Email'}
              error={errors.email && touched.email ? errors.email : undefined}
            >
              <CabinetTextInput
                id='email'
                type='text'
                name='email'
                value={values.email}
                onChange={handleChange}
              />
            </CabinetFormField>
          </FormCol>

          <FormCol>
            <CabinetFormField
              label={'Телефон'}
              error={errors.phone && touched.phone ? errors.phone : undefined}
            >
              <CabinetTextInputPhone
                type='tel'
                id='phone'
                name='phone'
                value={values.phone}
                onAccept={(value: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('phone', value)
                }
                onChange={handleChange}
                mask={process.env.NEXT_PUBLIC_PHONE_MASK}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormAction>
          <FormActionCol customWidth={50}>
            <CabinetButton type='submit' variant={'contained'}>
              Далее
            </CabinetButton>
          </FormActionCol>
        </FormAction>
      </CabinetFormTemplate>
    </Component>
  )
}

export default ServiceContactForm

const Component = styled.form``

const CabinetTextInputPhone = styled(IMaskInput)`
  ${InputStyles}
`
