import React from 'react'
import { cloneDeep } from 'lodash'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { useCabinetDataTable } from '../../useCabinetDataTable'
import { IDataTableRowComponents } from '../../types'

import { DataList } from './components/DataList'

interface Props {
  data: any
  rowComponent: React.ComponentType<IDataTableRowComponents>
  handlerSearch?: (value: string, list: any) => unknown[]
  handlerSort?: (value: string, list: any) => unknown[]
  handlerCheckbox?: (list: any) => unknown[]
  errorText: string
}

export const TableContent = ({
  data,
  rowComponent,
  errorText,
  handlerSearch,
  handlerSort,
  handlerCheckbox,
}: Props) => {
  const { searchQuery, isCheckboxChecked, sortValue, updateSortVisible } =
    useCabinetDataTable()

  const dataVisible = React.useMemo(() => {
    let sortList: any[] = cloneDeep(data)

    if (isCheckboxChecked && handlerCheckbox) {
      sortList = handlerCheckbox(sortList)
    }

    if (searchQuery && handlerSearch) {
      sortList = handlerSearch(searchQuery, sortList)
    }

    if (sortValue?.value && handlerSort) {
      sortList = handlerSort(sortValue.value, sortList)
    }

    sortList?.length === 0 ? updateSortVisible(false) : updateSortVisible(true)

    return sortList

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchQuery, isCheckboxChecked, sortValue])

  return (
    <Component>
      {dataVisible?.length > 0 ? (
        <DataList rowComponent={rowComponent} dataList={dataVisible} />
      ) : (
        <Text isError={!!errorText}>
          {errorText ? errorText : 'Данных по вашему запросу нет'}
        </Text>
      )}
    </Component>
  )
}

const Component = styled.div``

const Text = styled.p<{ isError: boolean }>`
  padding: 30px;
  color: ${props => props.theme.black100};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;

  ${({ isError, theme }) =>
    isError &&
    css`
      color: ${theme.red};
    `}
`
