import React, { PropsWithChildren } from 'react'

import { CardTemplateContext } from './CardTemplateContext'

import { CardDetailsType } from '@/services/users/typings'
import { Nullable } from '@/typings/common'

export const CardTemplateProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpen, setOpen] = React.useState(false)
  const [isLoading, setLoading] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<string>('')
  const [cardDetails, setCardDetails] =
    React.useState<Nullable<CardDetailsType>>(null)

  const updateOpennessStatus = React.useCallback((value: boolean) => {
    setOpen(value)
  }, [])

  const updateLoadingStatus = React.useCallback((value: boolean) => {
    setLoading(value)
  }, [])

  const updateErrorText = React.useCallback((value: string) => {
    setErrorText(value)
  }, [])

  const updateCardDetails = React.useCallback(
    (value: Nullable<CardDetailsType>) => {
      setCardDetails(value)
    },
    [],
  )

  return (
    <CardTemplateContext.Provider
      value={{
        isOpen,
        updateOpennessStatus,
        isLoading,
        updateLoadingStatus,
        errorText,
        updateErrorText,
        cardDetails,
        updateCardDetails,
      }}
    >
      {children}
    </CardTemplateContext.Provider>
  )
}
