import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

export const Field = styled.div`
  position: relative;

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator,
  input[type='time']::-webkit-inner-spin-button,
  input[type='time']::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
    opacity: 0;
  }

  .react-datepicker-popper {
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 0;
    z-index: 2000;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    border: 1px solid rgba(21, 27, 38, 0.15);
    box-sizing: border-box;
    box-shadow: 0px -12px 48px 8px rgba(34, 60, 80, 0.1);

    ${media.mobile(css`
      max-width: calc(100vw - 40px);
      width: 100%;
    `)}

    &__year-read-view--down-arrow {
      top: 6px;
      width: 7px;
      height: 7px;
    }

    &__year-dropdown {
      top: 50px;
      height: 200px;
    }

    &__triangle {
      display: none;
    }

    &__navigation {
      margin-top: 8px;
    }

    &__week {
      display: flex;
      justify-content: space-between;
    }

    &__month-container {
      width: 100%;
    }

    &__day--selected {
      background: ${props => props.theme.main};
    }

    &__navigation-icon {
      &:before {
        border-color: ${props => props.theme.black};
        border-width: 2px 2px 0 0;
      }
    }

    &__day--outside-month {
      opacity: 0.5;
    }

    &__current-month {
      margin-top: 5px;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    &__day {
      box-sizing: initial;
      border: 1px solid rgba(21, 27, 38, 0.15);
      border-radius: 4px;
      padding: 0 5px;
      width: 2.7rem;
    }

    &__day-names {
      margin: 0 0.4rem;
      display: flex;
      justify-content: space-between;
    }

    &__day-name {
      text-transform: capitalize;
      box-sizing: initial;
      padding: 0 5px;
    }

    &__header {
      font-weight: 700;
      font-size: 13px;
      line-height: 24px;
      background: ${props => props.theme.white};
      border: none;
      margin-bottom: -5px;
      padding-bottom: 0;
    }

    z-index: 10;
  }

  .badge-field {
    background: transparent;
  }

  svg {
    position: absolute;
    content: '';
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    pointer-events: none;
    width: 18px;
    height: 18px;
  }
`
