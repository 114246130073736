import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { CardTemplateProvider } from './CardTemplateProvider'

interface Props {
  children?: React.ReactNode
  isPointer?: boolean
}

function CardTemplate({ children, isPointer = true }: Props) {
  return (
    <CardTemplateProvider>
      <Card isPointer={isPointer}>{children}</Card>
    </CardTemplateProvider>
  )
}

const Card = styled.div<{ isPointer?: boolean }>`
  padding: 30px 30px 0;
  border-bottom: solid 1px ${props => props.theme.gray600};

  &:last-child {
    border-bottom: none;
  }

  ${({ isPointer }) =>
    isPointer &&
    css`
      cursor: pointer;
    `}
`

export default CardTemplate
