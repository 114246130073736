import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Cabinet from '@/modules/Cabinet'
import { OptionType } from '@/typings/common'
import { transformDate } from '@/utils/date'
import { useProfile } from '@/providers/Profile'
import { media } from '@/utils/mixin'
import BonusesHero from '@/modules/Bonuses/components/BonusesHero'
import { CabinetDataTable } from '@/modules/Cabinet/components/CabinetDataTable'
import { getUserBonuses } from '@/services/users/users-service'
import BonusDataCard from '@/modules/Cabinet/components/Cards/BonusDataCard'
import { handleSortCabinetDataTableByDate } from '@/modules/PersonalData/utils'
import { useDocumentTitle } from '@/hooks/useDocumentTitle'

const OPTIONS_LIST: OptionType[] = [
  {
    label: 'Сначала новые',
    value: 'new',
  },
  {
    label: 'Сначала старые',
    value: 'old',
  },
]

function Bonuses() {
  useDocumentTitle('Кабинет Атлант-М - Мои бонусы')
  const { updatePageLoading } = useProfile()

  React.useLayoutEffect(() => {
    async function fetchData() {
      // await Promise.all([dispatch(getUserBonusesPreviewThunk())]);
    }

    updatePageLoading(true)

    fetchData()
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        updatePageLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSearch(searchQuery: string, list: any) {
    return list?.filter((card: any) => {
      const bonusName = card.name?.toLowerCase()
      const bonusDealer = card.dealer?.toLowerCase()
      const bonusData = transformDate(card.date)
      const searchValue = searchQuery.toLowerCase()

      return (
        bonusName?.includes(searchValue) ||
        bonusDealer?.includes(searchValue) ||
        bonusData?.includes(searchValue)
      )
    })
  }

  return (
    <Cabinet activeTab={'BONUSES'}>
      <Wrapper>
        <BonusesHero />
        <Content>
          <Title>История накопления и трат бонусов</Title>
          <CabinetDataTable
            dataFetch={() => getUserBonuses()}
            rowComponent={BonusDataCard}
            search={{
              placeholder: 'Номер заказа, дата, дилер',
              handler: handleSearch,
            }}
            sort={{
              options: OPTIONS_LIST,
              handler: handleSortCabinetDataTableByDate,
            }}
          />
        </Content>
      </Wrapper>
    </Cabinet>
  )
}

export default Bonuses

const Wrapper = styled.div`
  box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);

  ${media.mobile(css`
    margin: 0 20px;
  `)}
`

const Content = styled.div`
  position: relative;
  padding: 20px 0 0;
  background-color: ${props => props.theme.gray300};

  ${({ theme }) => css`
    ${media.mobile(css`
      background-color: ${theme.white};
    `)}
  `}
`

const Title = styled.h2`
  margin: 0 30px 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.black100};
`
