import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { TableHeader } from './components/TableHeader'
import { TableContent } from './components/TableContent'
import { CabinetDataTableProvider } from './CabinetDataTableProvider'

import {
  IDataTableCheckbox,
  IDataTableRowComponents,
  IDataTableSearch,
  IDataTableSort,
} from '@/modules/Cabinet/components/CabinetDataTable/types'
import Spinner from '@/components/Spinner'
import { CabinetSelectProps } from '@/components/PersonalCabinetForms/components/CabinetSelect/CabinetSelect'

interface Props {
  dataFetch: () => Promise<any[]>
  rowComponent: React.ComponentType<IDataTableRowComponents>
  title?: string
  search?: IDataTableSearch
  sort?: IDataTableSort
  checkbox?: IDataTableCheckbox
  select?: CabinetSelectProps
}

export const CabinetDataTable = ({
  dataFetch,
  rowComponent,
  title,
  search,
  sort,
  checkbox,
  select,
}: Props) => {
  const [data, setData] = React.useState<any[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<string>('')

  useEffect(() => {
    setIsLoading(true)

    async function fetchData() {
      let [dataList] = await Promise.all([dataFetch()])

      if (dataList && !dataList[0]?.id) {
        dataList = dataList.map((data, index) => {
          return { ...data, id: index }
        })
      }

      setData(dataList)
    }

    fetchData()
      .catch(error => {
        console.log(error)
        setErrorText(error?.body?.message)
      })
      .finally(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select?.value])

  return (
    <CabinetDataTableProvider>
      <Component>
        {(title ||
          search ||
          sort ||
          checkbox ||
          (select?.options && select.options.length > 1)) && (
          <TableHeader
            title={title}
            search={search}
            sort={sort}
            checkbox={checkbox}
            select={select}
          />
        )}
        {!isLoading ? (
          <TableContent
            rowComponent={rowComponent}
            data={data}
            errorText={errorText}
            handlerSearch={search?.handler}
            handlerSort={sort?.handler}
            handlerCheckbox={checkbox?.handler}
          />
        ) : (
          <Loader>
            <Spinner absolute={true} isCabinet={true} />
          </Loader>
        )}
      </Component>
    </CabinetDataTableProvider>
  )
}

const Component = styled.div``

const Loader = styled.div`
  position: relative;
  min-height: 20vh;
  width: 100%;
`
