import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ICardDetailsContent } from './types'

import { media } from '@/utils/mixin'
import { CardDetailsType } from '@/services/users/typings'
import { useCardTemplate } from '@/modules/Cabinet/components/Cards/components/CardTemplate/useCardTemplate'
import Spinner from '@/components/Spinner'
import { Nullable } from '@/typings/common'
import useUpdateEffect from '@/hooks/useUpdateEffect'

interface Props {
  dataFetch?: () => Promise<Nullable<CardDetailsType>>
  defaultValue?: CardDetailsType
  content: React.ComponentType<ICardDetailsContent>
}

function CardDetails({ dataFetch, defaultValue, content: Content }: Props) {
  const {
    isOpen,
    cardDetails,
    updateCardDetails,
    errorText,
    updateErrorText,
    isLoading,
    updateLoadingStatus,
  } = useCardTemplate()

  async function getCardData() {
    if (!dataFetch) {
      return
    }

    const [data] = await Promise.all([dataFetch()])
    updateCardDetails(data)
  }

  useUpdateEffect(() => {
    if (defaultValue) {
      updateCardDetails(defaultValue)
    }

    if (isOpen && !cardDetails) {
      updateLoadingStatus(true)

      getCardData()
        .catch(error => {
          console.log(error)
          updateErrorText(error.body?.message ?? '')
        })
        .finally(() => {
          updateLoadingStatus(false)
        })
    }
  }, [isOpen, defaultValue, cardDetails])

  return (
    <Details isOpened={isOpen}>
      {isLoading ? (
        <Loader>
          <Spinner absolute={true} isCabinet={true} />
        </Loader>
      ) : (
        <DescriptionInner>
          {cardDetails ? (
            <Content cardDetails={cardDetails} />
          ) : (
            <Text>{errorText ?? 'Данных нет'}</Text>
          )}
        </DescriptionInner>
      )}
    </Details>
  )
}

const Details = styled.div<{ isOpened: boolean }>`
  display: none;
  margin: 0 -30px;

  ${({ isOpened }) =>
    isOpened &&
    css`
      display: block;
    `}
`

const DescriptionInner = styled.div`
  padding: 40px 60px 40px 130px;
  border-top: solid 1px ${props => props.theme.gray600};
  background-color: ${props => props.theme.white};

  ${media.tabletSmall(css`
    padding: 40px;
  `)}

  ${media.mobile(css`
    padding: 40px 30px;
  `)}
`

const Loader = styled.div`
  position: relative;
  border-top: solid 1px ${props => props.theme.gray600};
  background-color: ${props => props.theme.white};
  min-height: 15vh;
  width: 100%;
`

const Text = styled.p<{ isError?: boolean }>`
  padding: 30px;
  color: ${props => props.theme.black100};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;

  ${({ isError, theme }) =>
    isError &&
    css`
      color: ${theme.red};
    `}
`

export default CardDetails
