import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { StringField } from '@/typings/common'

interface Props {
  rowData: { row: string[]; name: StringField }
  headerColList: string[]
}

function RowMobile({ rowData, headerColList }: Props) {
  return (
    <Component>
      {rowData.row.length !== 2 && (
        <Col>
          <Name>{rowData.name}</Name>
        </Col>
      )}

      {headerColList.map((col, index) => (
        <Col key={index}>
          <Key>{col}</Key>
          <Value
            dangerouslySetInnerHTML={{ __html: rowData.row[index] ?? '' }}
          />
        </Col>
      ))}
    </Component>
  )
}

const Component = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Name = styled.div``

const Key = styled.div`
  width: 35%;
  margin-right: 20px;
  color: ${props => props.theme.gray500};
`

const Value = styled.div``

const Col = styled.div`
  display: flex;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${props => props.theme.blue100};

  &:first-child {
    margin-bottom: 8px;
  }

  ${media.mobile(css`
    margin-bottom: 5px !important;
  `)}
`

export default RowMobile
