import React from 'react'

import ModalWindow from '@/components/modals/ModalWindow'
import { ModalWindowProps } from '@/components/modals/ModalWindow/types'
import { CarValuationFormModalProps } from '@/components/PersonalCabinetForms/modals/CarValuationFormModal/types'
import CarValuationFormModal from '@/components/PersonalCabinetForms/modals/CarValuationFormModal'

export interface Props
  extends Omit<ModalWindowProps, 'children' | 'maxWidth'>,
    CarValuationFormModalProps {}

function CarValuationModal(props: Props) {
  return (
    <ModalWindow {...props} onRequestClose={props.closeModal} maxWidth={790}>
      <CarValuationFormModal {...props} />
    </ModalWindow>
  )
}

export default CarValuationModal
