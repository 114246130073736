import React from 'react'
import styled from '@emotion/styled'

import { getSpareRow, getTableHeaderList } from '../../helpers'

import CardTable from '@/modules/Cabinet/components/Cards/components/CardTemplate/components/CardTable'
import { CardDetailsType } from '@/services/users/typings'

interface Props {
  cardDetails: CardDetailsType
}

function CardDetailsContentSpare({ cardDetails }: Props) {
  return (
    <Component>
      {cardDetails?.spare && (
        <CardTable
          headerColList={getTableHeaderList('SPARE')}
          dataTable={getSpareRow(cardDetails.spare)}
          title={'Запчасть'}
        />
      )}
    </Component>
  )
}

const Component = styled.div``

export default CardDetailsContentSpare
