import React from 'react'
import { Form, FormikProps } from 'formik'
// @ts-ignore
import { IMaskInput } from 'react-imask'
import styled from '@emotion/styled'

import { ReactComponent as ArrowIcon } from '@/assets/svg/login/arrow-right.svg'
import Spinner from '@/components/Spinner'
import { AddPhonePayload } from '@/services/users/typings'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'

export type AddPhoneFormValues = AddPhonePayload

type Props = FormikProps<AddPhonePayload> & {
  isLoading: boolean
  isPhoneSend: string | null
}

function AddPhoneForm({
  isLoading,
  isPhoneSend,
  handleChange,
  values,
  errors,
}: Props) {
  return (
    <Form autoComplete='off' noValidate>
      <TextFieldWrapper>
        {isPhoneSend ? (
          <CabinetFormField
            error={errors.code ?? ''}
            label={'Код подтверждения'}
          >
            <Input
              type='text'
              id='code'
              name='code'
              mask='0000'
              value={values.code}
              placeholder='Код из СМС-сообщения'
              onChange={handleChange}
            />
          </CabinetFormField>
        ) : (
          <CabinetFormField error={errors.phone ?? ''} label={'Телефон'}>
            <Input
              type='tel'
              id='phone'
              name='phone'
              mask={process.env.NEXT_PUBLIC_PHONE_MASK}
              value={values.phone}
              onChange={handleChange}
            />
          </CabinetFormField>
        )}
        <SubmitButton disabled={isLoading} type={'submit'}>
          {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
        </SubmitButton>
      </TextFieldWrapper>
    </Form>
  )
}

export default AddPhoneForm

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  max-width: 320px;
`

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.main};
  transition: 0.3s;

  &:hover {
    background: ${props => props.theme.main100};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background: ${props => props.theme.main};
    }
  }
`

const Input = styled(IMaskInput)`
  ${InputStyles}
`
