import { Nullable } from '@/typings/common'
import { ServiceTOPayloadDetails } from '@/services/users/typings'

export enum ServiceWorkTypes {
  REQUIRED = 'Required',
  RECOMMENDED = 'Recommended',
  REQUIRED_AND_RECOMMENDED = 'Required_And_Recommended',
}

export interface ServiceDetailsProps {
  detailsData: Nullable<ServiceTOPayloadDetails>
  workType?: ServiceWorkTypes
}
