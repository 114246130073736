import React from 'react'
import Modal, { Props } from 'react-modal'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ModalWindowProps } from './types'

import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg'
import { ReactComponent as ArrowLeftIcon } from '@/assets/svg/arrow-left.svg'
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll'
import { media } from '@/utils/mixin'
import Button from '@/components/Button'

Modal.setAppElement('body')

function ModalDecorator({ className, ...props }: Props) {
  const name =
    className && typeof className === 'string' ? className.split(' ')[0] : ''

  return (
    <Modal
      {...props}
      portalClassName={name}
      overlayClassName={`${name}__Overlay`}
      className={`${name}__Content`}
    />
  )
}

function ModalWindow({
  children,
  maxWidth,
  onRequestClose,
  isOpen,
  withBackButton,
  withInnerClose,
  ...props
}: ModalWindowProps) {
  useLockBodyScroll({ lockIfOpened: isOpen })

  return (
    <StyledModal
      {...props}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      maxWidth={maxWidth}
      closeTimeoutMS={150}
    >
      <Header>
        {withBackButton ? (
          <BackButton onClick={onRequestClose}>
            <ArrowLeftIcon />
            Назад
          </BackButton>
        ) : (
          <CloseButton onClick={onRequestClose}>
            <CloseIcon />
          </CloseButton>
        )}
      </Header>

      <Content>
        {children}

        {withInnerClose && (
          <InnerClose>
            <Button variant='outlined' size='small' onClick={onRequestClose}>
              Закрыть
            </Button>
          </InnerClose>
        )}
      </Content>
    </StyledModal>
  )
}

export default ModalWindow

const StyledModal = styled(ModalDecorator)<{
  maxWidth?: ModalWindowProps['maxWidth']
}>`
  .ReactModal__Overlay {
    display: flex;
    position: fixed;
    z-index: 10001;
    inset: 0;
    overflow-y: auto;
    padding: 80px 40px;
    background: ${({ theme }) => theme.black100_alpha50};
    opacity: 0;
    transition: 0.15s;

    ${media.tablet(css`
      padding: 80px 0 0;
    `)}

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Content {
    width: 100%;
    margin: auto;
    padding: 20px 30px 40px;
    background: ${({ theme }) => theme.white};
    border-radius: 20px;

    ${media.tablet(css`
      border-radius: 20px 20px 0 0;
      margin: auto 0 0;
      padding: 20px;
    `)}

    ${({ maxWidth }) =>
      maxWidth &&
      css`
        max-width: ${maxWidth}px;

        ${media.tablet(css`
          max-width: none;
        `)}
      `}
  }
`

const Header = styled.div`
  display: flex;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.black};

  svg {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: ${({ theme }) => theme.black};
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.gray300};
  margin-left: auto;

  &:hover {
    background: ${({ theme }) => theme.gray200};
    border-color: ${({ theme }) => theme.gray};
  }

  svg {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.black};
  }
`

const Content = styled.div`
  margin-top: 10px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}
`

const InnerClose = styled.div`
  max-width: 240px;
  margin: 40px auto 0;

  ${media.tablet(css`
    max-width: none;
    margin: 20px auto 0;
  `)}
`
