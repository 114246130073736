import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StepsProps } from './types'

import { ReactComponent as TickIcon } from '@/assets/svg/checkbox-tick.svg'

function Steps({ title, steps, activeStep = steps[0] }: StepsProps) {
  return (
    <Component>
      <Title>{title}</Title>
      <List>
        {steps.map((step, index) => (
          <Step key={index}>
            <StyledTickIcon active={step === activeStep} />
            <StepTitle active={step === activeStep}>{step}</StepTitle>
          </Step>
        ))}
      </List>
    </Component>
  )
}

export default Steps

const Component = styled.div``

const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.blue100};
`

const List = styled.ul`
  margin-top: 10px;
`

const Step = styled.li`
  position: relative;
  display: flex;
  padding-left: 30px;

  &:not(:first-child) {
    margin-top: 15px;
  }
`

const StyledTickIcon = styled(TickIcon)<{ active?: boolean }>`
  position: absolute;
  top: 2px;
  left: 0;

  path {
    fill: ${props => props.theme.gray500};
    stroke: ${props => props.theme.gray500};

    ${({ active, theme }) =>
      active &&
      css`
        fill: ${theme.main};
        stroke: ${theme.main};
      `}
  }
`

const StepTitle = styled.p<{ active?: boolean }>`
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.gray500};

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.main};
    `}
`
