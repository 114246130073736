import React, { PropsWithChildren } from 'react'

import { CabinetDataTableContext } from './CabinetDataTableContext'

import { Nullable, OptionType } from '@/typings/common'

export const CabinetDataTableProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [isCheckboxChecked, setCheckboxStatus] = React.useState(false)
  const [sortVisible, setSortVisible] = React.useState(true)
  const [sortValue, setSortValue] = React.useState<Nullable<OptionType>>(null)

  const updateCheckboxStatus = React.useCallback((value: boolean) => {
    setCheckboxStatus(value)
  }, [])

  const updateSortVisible = React.useCallback((value: boolean) => {
    setSortVisible(value)
  }, [])

  const updateSearchQuery = React.useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const updateSortValue = React.useCallback((value: OptionType) => {
    setSortValue(value)
  }, [])

  return (
    <CabinetDataTableContext.Provider
      value={{
        searchQuery,
        updateSearchQuery,
        isCheckboxChecked,
        updateCheckboxStatus,
        sortValue,
        updateSortValue,
        sortVisible,
        updateSortVisible,
      }}
    >
      {children}
    </CabinetDataTableContext.Provider>
  )
}
